#page_login {
  background-color: #0c1a25;
  // background-image: url("../../assets/images/backgrounds/login-figma.png");
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;

  #login_card {
    background-color: rgba(251, 252, 252, 0.8);
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
    // height: 500px;
    height: fit-content;
    padding: 0px 5%;
    // margin-top: 125px;
    top: 0;
  }

  #login_background_vid {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100vw;
    min-height: 100vh;
    max-height: none !important;
    max-width: none !important;
    // z-index: -1 !important;
  }

  // SMART IMAGE MANAGEMENT -> HEADER
  h3 {
    /* font-family: Arial, Helvetica, sans-serif; */
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 600;
    color: var(--main-color-text) !important;
    font-size: 1.8em;
  }

  @media screen and (max-width: 320px) {
    .smallHeaderFont {
      font-size: 20px;
    }
  }

  #mdi_logo {
    width: 200px;
    position: fixed;
    top: 3%;
    left: 4%;
  }

  .uk-button.btn_primary {
    font-size: 1.1em;
    font-weight: 100;

    &.login_btn {
      margin: auto;
      display: block;
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  .back_btn {
    position: absolute;
    top: 15px;
    left: -10px;
  }

  #btn_2fa {
    margin-left: 3px;
  }

  #btn_magic_link {
    margin-left: -6px;
  }

  #login_toggler {
    padding-bottom: 20px;
    text-transform: capitalize;

    .uk-button.btn_primary {
      text-transform: capitalize;
    }

    .uk-button.btn_primary:hover,
    .uk-button.btn_primary.active:hover {
      font-weight: 400 !important;
    }

    .uk-button.btn_primary:hover {
      background-color: var(--btn-primary-color);
    }

    .uk-button.btn_primary.active:hover {
      background-color: var(--btn-primary-hover-color) !important;
    }
  }

  .uk-input,
  .uk-form-label {
    display: inline-block;
  }

  .uk-form-label {
    width: 50%;
    font-size: 1.2em;
    /* padding-left: 10%; */
  }

  .uk-input {
    width: 50%;
    background-color: #434961;
    color: white;
    border-radius: 5px;

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #bebebe !important;
      opacity: 1;
      /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #bebebe !important;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #bebebe !important;
    }
  }

  .uk-margin.login_input_section {
    margin-top: 0px;
    margin-bottom: 40px;
  }

  .fontSizeSent {
    font-size: 20px;
    font-weight: 600;
  }

  .span_incorrect {
    color: red;
    font-weight: 100;
    text-align: center;
    display: block;
    margin-top: 10px;

    &.hidden {
      display: none;
    }

    &.shown {
      display: block;
    }
  }

  .under_line {
    border-bottom: 1px solid var(--main-color-dark);
    width: fit-content;
    color: var(--main-color-dark);
    font-size: 0.8em;
    margin: auto;
    margin-bottom: 0px;

    .uk-checkbox {
      border-color: #222d59;
    }
  }

  #span_request {
    text-align: center;
    font-weight: 600;

    a {
      color: var(--main-color);
    }
  }

  #login_toggler_inner {
    margin: auto;
  }

  .disabled {
    cursor: not-allowed !important;
  }
}
