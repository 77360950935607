#page_pending {
  background-color: #0c1a25;
  background-image: url("../../assets/images/backgrounds/login-figma.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  // overflow: hidden;

  #pending_card {
    background-color: rgba(251, 252, 252, 0.8);
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
    // height: 500px;
    height: fit-content;
    max-height: 555px;
    padding: 10px 10px;
    margin-top: 125px;
    top: 0;
    // overflow-y: auto;
  }

  .outer_card {
    padding: 20px;
  }

  .reg_container {
    padding: 0;
    margin: 0;
    justify-content: center;
  }

  .reg_form {
    padding-left: 0 !important;
  }

  .btn_container {
    display: flex !important;
    align-items: start !important;
    justify-content: end !important;
  }
  .middle_gutter {
    padding: 0;
  }

  // MDI KOALA
  .left_container {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 30px !important;
  }

  .selection {
    width: 100%;
    height: 40px;
    background-color: #434961;
    color: #bebebe;
    border-radius: 5px;
    padding: 0px 10px;
    text-transform: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1em;

    option {
      color: #fff;
    }

    .default_selection {
      color: #bebebe;
    }
  }
  .selected {
    color: #fff;
  }

  .sim_use_textarea {
    background-color: #434961 !important;
    color: #fff !important;
    max-height: 80px !important;
    border-radius: 5px;
    border-color: #434961;

    &::placeholder {
      color: #bebebe !important;
    }
  }

  .sim_use_label {
    font-size: 1rem !important;
    width: 100% !important;
    // color: #bebebe;
    // margin-bottom: 10px;
  }

  .mobile_container {
    display: flex;
    justify-content: start;
    align-items: center;

    .cc_selector {
      margin-right: 15px !important;
    }
  }

  .sub_error_container {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .error_container {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .span_incorrect {
      color: red;
      font-weight: 100;

      &.hidden {
        visibility: hidden;
      }

      &.shown {
        visibility: visible;
      }
    }
  }

  .uk-dropdown {
    padding: 10px;
  }

  h3 {
    /* font-family: Arial, Helvetica, sans-serif; */
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 600;
    color: var(--main-color-text) !important;
    font-size: 1.8em;
  }

  @media screen and (max-width: 320px) {
    .smallHeaderFont {
      font-size: 20px;
    }
  }

  #mdi_logo {
    width: 200px;
    position: fixed;
    top: 3%;
    left: 4%;
  }

  .uk-button.btn_primary {
    font-size: 1.1em;
    font-weight: 100;

    &.login_btn {
      // margin: auto;
      display: block;
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  .uk-input,
  .uk-form-label {
    display: inline-block;
  }

  .uk-form-label {
    width: 50%;
    font-size: 1.2em;
    /* padding-left: 10%; */
  }

  .uk-input {
    width: 100%;
    background-color: #434961;
    color: white;
    border-radius: 5px;
    border-color: #434961;

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #bebebe !important;
      opacity: 1;
      /* Firefox */
    }

    &textarea::placeholder {
      color: #bebebe; /* Placeholder color */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #bebebe !important;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #bebebe !important;
    }
  }

  .disabled {
    cursor: not-allowed !important;
  }

  .invalid-input {
    box-shadow: 2px 2px 0.25rem #ff0000;
  }

  @media screen and (max-width: 750px) {
    .left_container {
      display: none;
    }

    #mdi_logo {
      width: 150px;
    }

    #pending_card {
      margin-top: 85px;
      max-height: calc(100svh - 85px);
      overflow-y: auto;

      .outer_card {
        padding: 5px;
      }
    }

    .span_incorrect {
      font-size: 0.85rem;
    }
  }
}

#confirmation-modal {
  z-index: 10;
  position: absolute;
  // overflow-y: auto;
  max-height: calc(100svh - 30px);

  .modal_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .uk-modal-title {
    color: var(--main-color-text);
  }

  .uk-grid-small {
    margin-left: 0px !important;
    padding-left: 30px !important;
  }

  .iconsNavMenu {
    width: 10%;
    padding-top: 2%;
    padding-bottom: 2%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    transition: background-color 0.1s ease-in-out;
    opacity: 1;
    transform: scale(0.75);
    transition: transform 0.2s ease;

    &:hover {
      // background-color: var(--main-color-text-light-blue);
      // background-color: var(--main-color-text-light-blue);
      cursor: pointer;
    }

    .iconSizesNav {
      width: 40%;
      min-width: 20px;
      max-width: 30px;
      height: auto;
      fill: var(--main-color-dark);
    }
  }

  .modal_details_container {
    padding-left: 0px !important;
  }

  .modal_details {
    margin-left: 0px;
    padding-left: 30px;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .modal_detail_single {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .modal_label {
    display: flex;
    justify-content: start;
    text-align: start;
  }

  .modal_confirmation_details {
    // width: 100%;
    background-color: #434961;
    color: white;
    border-radius: 5px;
    border-color: #434961;
    min-height: 40px;
    max-height: 300px;
    height: fit-content !important;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 10px;
    text-align: left;
    overflow: hidden;
  }

  .btn_container {
    display: flex;
    justify-content: flex-end;
  }

  .confirm_btn {
    display: block;
    padding: 5px 30px;
    font-size: 1rem;
  }

  @media screen and (max-width: 750px) {
    #confirmation-modal {
      overflow-y: auto;
    }
    .modal_details {
      padding: 0;
    }

    .uk-modal-title {
      font-size: 1.5rem;
    }
  }

  .iconsNavMenu {
    width: 25%;
  }
}

.uk-margin.login_input_section {
  margin-top: 0px;
  margin-bottom: 40px;
}
