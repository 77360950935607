:root {
  --top-container-padding: 10%;
}
.imageAttributesContainer table {
  width: 95%;
  border-collapse: collapse;
}

.imageAttributesContainer {
  align-self: left;
  padding: 20px;
  justify-self: center;
  margin-top: 1rem;
  margin-left: var(--top-container-padding);
  margin-right: var(--top-container-padding);
  width: 95%;
  border-radius: 5px;
  background-color: rgba(191, 219, 240, 0.9098039216);

  @media (max-width: 960px) {
    margin-left: 0px;
  }

  .imageAttributesTitle {
    width: 95%;
    display: flex;
    justify-content: space-between;

    .title {
      width: 70%;
      margin: 0px !important;
      align-content: left;
      padding: 0px !important;
    }
  }
}
//media query for smaller screens

.imageAttributesContainer table,
.imageAttributesContainer th,
.imageAttributesContainer td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.imageAttributesContainer th {
  background-color: #1f8dc761;
  align-self: center;
  width: auto;
}

.singleViewTable {
  overflow-wrap: anywhere;
}

.table_column {
  width: 30%;
  overflow-wrap: initial;
}

.assignAttributeButton {
  text-align: center;
  max-height: 40px;
  border-radius: 10px;
  margin: 2px !important;
}

@media (max-width: 960px) {
  .assignAttributeButton {
    margin-right: 30px;
  }
}

@media (max-width: 780px) {
  .assignAttributeButton {
    margin-right: 35px;
  }
}

@media (max-width: 700px) {
  .assignAttributeButton {
    margin-right: 10px;
  }
}

.assignment_div,
removal_div {
  width: 93%;
  display: flex;
  padding: 5px;
  margin-bottom: 5px;

  #selectedAttribute,
  #value {
    font-family: "Comfortaa" !important;
    font-size: 14px;
    font-weight: 800 !important;
    width: 45%;
    border-radius: 10px;
    border: 1px solid black;
    background-color: #fff;
    margin: 2px !important;
    padding-left: 5px !important;
    padding-right: 2px !important;

    option {
      font-family: "Comfortaa" !important;
      font-size: 14px !important;
      font-weight: 600 !important;
      background-color: white !important;
      border: 1px solid black;
      border-radius: 10px;
    }
  }

  #assignAttributeButton {
    font-family: "Comfortaa" !important;
    font-size: 14px;
    font-weight: 800 !important;
    width: 20%;
    border-radius: 10px;
    background-color: #1f8dc761;
    margin: 2px !important;
    border: 1px solid black;
    // box-shadow: 0px 0px 3px 0px #000f3a;
  }
}

.attributeError {
  color: #bb2d3b;
  text-align: center;
  font-weight: 800 !important;
}

.attributeAssignment {
  margin-left: 10px;
  color: green;
  text-align: center;
  font-weight: 800 !important;
}

.noUserAttributesContainer {
  width: 95%;
  display: flex;
  justify-content: space-between;
}

.ManageAttributesNavBtn {
  text-align: center;
  font-family: "Comfortaa" !important;
  font-size: 14px;
  font-weight: 800 !important;
  max-height: 30px;
  border-radius: 10px;
  background-color: #1f8dc761;
  margin: 2px !important;
  border: 1px solid black;
}
.removal-row {
  border: 4px solid white;
  border-bottom: none;
}

.remove-item {
  font-weight: 800;
}

.removal-div-border {
  border: 4px solid white;
  border-top: none;
}
