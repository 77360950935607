#upload_image_page_component_container {
  padding-bottom: 20px;

  .upload_form_container form {
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 3px 0px 15px 0px #00000035;
    padding: 20px;
  }

  .upload_form_submit {
    margin-bottom: 0px !important;
    padding: 6px !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
    margin-top: 0px !important;
    color: var(--main-color-dark);
    border-radius: 10px;
    border: solid 1px;
    border-color: var(--main-color-text-light-blue);
    background-color: #8cc1e8;
    z-index: 1;
    cursor: pointer;
  }
}

#upload_form {
  text-align: center;
  min-width: 270px;
}

.form_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.upload_form_container {
  margin: 20px !important;
  border-radius: 5px;
}

.upload_icon {
  max-width: 50px;
  height: 50px;
  margin-top: 10px;
  margin-bottom: 10px;
  path.b {
    fill: #fff !important;
  }
  .darkmode & {
    path.b {
      fill: var(--main-color-dark) !important;
    }
  }
}

.upload_form_title {
  white-space: nowrap;
}

@media screen and (max-width: 900px) {
  #upload_image_page_component_container {
    padding-left: 40px;
    margin-left: auto;
  }
}

.darkmode #upload_image_page_component_container {
  .upload_form_container form {
    background-color: #1c2650;
    color: #ffffff !important;
  }

  .upload_form_title {
    color: #ffffff !important;
  }
}

#upload_image_multiple_view {
  // padding: 20px;
  padding-left: 0px;
  max-height: calc(100svh - 200px);
  overflow-y: auto;

  #images_container {
    padding-bottom: 0px;
  }

  img {
    max-width: 100%;
    height: auto;
    object-fit: cover;
  }

  #images_grid .uk-card {
    padding-top: 70% !important;
    border-radius: 5px;
    cursor: pointer;
    background-size: cover;
    background-position: center center;
    box-shadow: 0px 0px 3px 0px #979cab;
    transition: all 0.1s ease-in-out !important;

    &:hover {
      box-shadow: 0px 0px 5px 0px #676b75;
    }
  }

  .edit_icon {
    position: absolute;
    width: 30%;
    height: auto;
    top: 30%;
    left: 35%;
    opacity: 0.7;
    transform: scaleX(-1);
    // make white
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg)
      brightness(100%) contrast(100%)
      drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.4));
    transition: all 0.1s ease-in-out !important;
  }

  .uk-card:hover {
    .edit_icon {
      opacity: 1;
    }
  }

  .btn {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 5px;
    padding: 6px;
    padding-left: 8px;
    padding-right: 8px;
    color: var(--main-color-dark);
    border-radius: 10px;
    border: none;
    border-color: var(--main-color-text-light-blue);
    background-color: #8cc1e8;
    cursor: pointer;
    font-size: 1rem;
  }
}

#upload_form_container_main_card {
  max-height: calc(100svh - 200px);
  overflow-y: auto;
}

#upload_form_container {
  @media screen and (min-width: 900px) {
    padding-right: 40px;
  }

  // #upload_form_container_main_card {
  //   padding: 30px;
  // }

  .uk-card {
    // background-color: red;
    border-radius: 5px;
  }

  img {
    border-radius: 5px;
  }

  // .right_side {
  //   padding-left: 30px;
  // }

  .btn {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 5px;
    padding: 6px;
    padding-left: 8px;
    padding-right: 8px;
    color: var(--main-color-dark);
    border-radius: 10px;
    border: none;
    border-color: var(--main-color-text-light-blue);
    background-color: #8cc1e8;
    cursor: pointer;
    font-size: 1rem;

    &.btn_cancel {
      background-color: #a2adb4;
    }
  }
}

input[type="file"] {
  display: none;
}
#uploadPage {
  height: 100%;
}
.upload_page {
  height: 86vh;
  .upload_container {
    overflow-y: scroll;
    height: 100%;

    .header_icon_container {
      display: flex;
      justify-content: space-between;
      margin: 30px 10px 0px 10px;

      h2 {
        width: 50%;
        height: fit-content;
        align-self: left;
      }

      @media screen and (max-width: 768px) {
        h2 {
          width: 100% !important;
          margin-bottom: 30px !important;
        }
      }

      .logo_button {
        border: none;
        width: 170px;
        height: 100px;
        background-repeat: no-repeat;
        background-size: contain;
        background-color: transparent;
        margin-right: 50px;
        transform: scale(1.5);
      }
    }

    //Lets make the scroll bar
  }
}

.upload_form_submit_label {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 5px;
  padding: 6px;
  padding-left: 8px;
  padding-right: 8px;
  color: var(--main-color-dark);
  border-radius: 10px;
  border: none;
  border-color: var(--main-color-text-light-blue);
  background-color: #8cc1e8;
  z-index: 1;
  cursor: pointer;
}

@media screen and (max-width: 900px) {
  #upload_image_multiple_view {
    padding-left: 30px;
    padding-right: 0px;
  }
}

.darkmode {
  #uploadPage {
    h1 {
      color: white;
    }
  }

  #upload_form_container_main_card {
    background-color: #ffffff0c !important;
    color: #ffffff !important;

    .uk-card-default {
      background-color: #1a2345;
      color: #ffffff !important;

      label {
        color: #ffffff !important;
      }

      input {
        background-color: #2a2e3f !important;
        color: #fff !important;
      }
    }
  }
  #upload_image_multiple_view {
    #images_grid .uk-card {
      box-shadow: 0px 0px 3px 0px #000000c7;

      &:hover {
        box-shadow: 0px 0px 5px 0px #000000;
      }
    }
  }
}
