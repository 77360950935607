#dashboard {
  // display: inline-block;
  // width: calc(100% - var(--sidebar-width));

  .inner_container {
    padding-left: 0px;
  }
}

.landingPageContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;

  .left-container {
    width: 50%;
  }

  .right-container {
    width: 50%;
  }
}

.title-flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 1rem;

  .title {
    font-size: 2rem;
    font-weight: 600;
    margin: 0 10px;
  }

  .active-title,
  .inactive-title {
    transition: color 0.3s ease, font-size 0.3s ease;
    cursor: pointer;
  }

  .active-title {
    color: var(--primary-color);
    font-size: 2.5rem;
  }

  .inactive-title {
    color: grey;
    font-size: 1.5rem;
  }

  .divider {
    margin: 10px 20p;
    font-size: 1.5rem;
    color: grey;
  }
}

.layout-options {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0px;
  padding-right: 10px;

  .layout-title {
    margin: 0px !important;
  }

  .option-icons {
    width: 20px;
    height: 20px;
    fill: gray;
    margin: 4px;
  }

  .selected {
    fill: var(--primary-color);
  }

  .option-icons:hover {
    fill: var(--primary-color);
    cursor: pointer;
  }
}

.container {
  position: absolute;
  transition: transform 0.5s ease-in-out;
}

.client,
.boards {
  position: fixed;
  width: 100%;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.selected {
  transform: translateY(0);
  z-index: 2;
  opacity: 1;
}

.unselected {
  transform: translateY(100%);
  z-index: 1;
  opacity: 0;
  pointer-events: none;
}

.darkmode {
  .title-flex-container {
    .active-title {
      color: #8dc3ea;
    }
  }

  .layout-options {
    .layout-title {
      color: #fff;
    }

    .selected {
      fill: #8dc3ea;
    }

    .option-icons:hover {
      fill: #fff;
    }
  }
}
