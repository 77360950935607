/* Koala search section */

#searchKoalaMain {
  .koalaSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 19px;
  }

  .imageContainer {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .koalaImage {
    height: auto !important;
    width: 30%;
  }

  .words {
    padding-top: 6px;
    font-size: 20px;
  }

  /* Results section */
}

@media screen and (max-width: 800px) {
  .koalaImage {
    width: 200% !important;
    max-width: 90%;
    max-height: calc(100vh - 120px);
  }
}

.darkmode {
  #searchKoalaMain {
    .words {
      color: #ffffff !important;
    }
  }
}
