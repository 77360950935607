#error_page {
  width: 100vw !important;
  height: 100vh !important;
  overflow: hidden;
  background-color: #273361;

  #error_card {
    height: auto;
    margin: auto;
    margin-top: 25vh;
    text-align: center;
    padding: 50px;
    border-radius: 10px;

    h1,
    p {
      color: white !important;
      font-weight: 900;
    }

    h1 {
      font-size: 10em;
    }

    p {
      font-size: 2em;
    }

    button {
      margin-bottom: 0px !important;
      padding: 6px !important;
      padding-left: 8px !important;
      padding-right: 8px !important;
      margin-top: 0px !important;
      color: var(--main-color-dark);
      border-radius: 10px;
      border: none;
      border-color: var(--main-color-text-light-blue);
      background-color: #8cc1e8;
      z-index: 1;
      cursor: pointer;
      font-size: 1.2em;

      &:hover {
        background-color: #5897c4;
      }
    }
  }
}
