#board_creation {
  #client_list {
    height: 2.625em;
    width: 80%;
    margin-top: 1em;

    transition: border-color 0.1s, box-shadow 0.1s;
    border: 1px solid #d9d9d9;
    border-radius: 0.1875em;
    background: rgba(0, 0, 0, 0);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.06), 0 0 0 3px rgba(0, 0, 0, 0);
    color: inherit;
    font-size: 1rem;
    max-height: 50px;
    overflow-y: auto;
  }

  #board_name {
    height: 2.625em;
    width: 80%;
    margin-top: 0.25em;
    font-size: 1rem;
    // change placeholder color
    &::placeholder {
      color: #545454 !important;
    }
  }
}

// .swal2-actions {
//   display: flex;
//   justify-content: space-between;
//   margin: 0.5em 7em 0 7em;

//   .button {
//     margin: 0;
//   }

//   // .swal2-confirm {
//   //   // margin-right: 5.5em;
//   // }
// }
