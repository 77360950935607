:root {
  --index: calc(1vw + 1vh);
  --transition: cubic-bezier(0.1, 0.7, 0, 1);
}

body {
  background-color: #141414;
}

.carousel-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem 0rem;

  .all-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 0.8rem;
    height: 72vh;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    .item-row {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.8rem;
      width: 100%;

      @media screen and (max-width: 280px) {
        height: 45%;
      }

      @media screen and (min-width: 281px) and (max-width: 480px) {
        height: 54%;
      }

      @media screen and (min-width: 481px) and (max-width: 788px) {
        height: 65%;
      }

      @media screen and (min-width: 789px) and (max-width: 1024px) {
        height: 78%;
      }

      @media screen and (min-width: 1025px) and (max-width: 1200px) {
        height: 82%;
      }

      @media screen and (min-width: 1201px) and (max-width: 1400px) {
        height: 90%;
      }
    }
  }

  .items {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.8rem;
    perspective: calc(var(--index) * 35);
    width: 70%;

    .item {
      display: flex;
      width: calc(var(--index) * 4);
      height: calc(var(--index) * 16);
      background-color: #222;
      background-size: cover;
      background-position: center;
      cursor: pointer;
      filter: grayscale(1) brightness(0.5);
      transition: transform 1.25s var(--transition), filter 3s var(--transition),
        width 1.25s var(--transition);
      will-change: transform, filter, rotateY, width;
      border-radius: 5px;
      box-shadow: 1px 1px 5px 0px #000;
      position: relative;
      z-index: 3;
      flex: 0 1 auto; /* Prevents flex items from growing or shrinking */

      .item-title {
        position: absolute;
        bottom: 0;
        padding: 10px 0px;
        height: 15%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
        color: white;
        text-align: center;
        transition: opacity 0.5s ease-in-out;

        .footer-title {
          display: block;
          color: #fff;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 90%;
          height: 100%;
        }
      }

      .share-overlay {
        z-index: 2;
        position: absolute;
        top: 0;
        padding: 10px 0px;
        height: 15%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
        color: white;
        text-align: center;
        transition: opacity 0.5s ease-in-out;
        fill: #fff;
      }

      .image-overlay {
        width: 100%;
        height: 100%;
      }
      .image-overlay:hover {
        transform: scale(1.1);
        transition: transform 0.5s ease-in-out;
      }
    }

    .item::before,
    .item::after {
      content: "";
      position: absolute;
      height: 100%;
      width: 20px;
      right: calc(var(--index) * -1);
    }

    .item::after {
      left: calc(var(--index) * -1);
    }

    .item:hover {
      filter: inherit;
      transform: translateZ(calc(var(--index) * 10));
    }

    /*Right*/
    .item:hover + * {
      filter: inherit;
      transform: translateZ(calc(var(--index) * 8.5)) rotateY(35deg);
      z-index: -1;
    }
    .item:hover + * + * {
      filter: inherit;
      transform: translateZ(calc(var(--index) * 5.6)) rotateY(40deg);
      z-index: -2;
    }

    .item:hover + * + * + * {
      filter: inherit;
      transform: translateZ(calc(var(--index) * 2.5)) rotateY(30deg);
      z-index: -3;
    }

    .item:hover + * + * + * + * {
      filter: inherit;
      transform: translateZ(calc(var(--index) * 0.6)) rotateY(15deg);
      z-index: -4;
    }

    /*Left*/
    .item:has(+ :hover) {
      filter: inherit;
      transform: translateZ(calc(var(--index) * 8.5)) rotateY(-35deg);
    }
    .item:has(+ * + :hover) {
      filter: inherit;
      transform: translateZ(calc(var(--index) * 5.6)) rotateY(-40deg);
    }

    .item:has(+ * + * + :hover) {
      filter: inherit;
      transform: translateZ(calc(var(--index) * 2.5)) rotateY(-30deg);
    }

    .item:has(+ * + * + * + :hover) {
      filter: inherit;
      transform: translateZ(calc(var(--index) * 0.6)) rotateY(-15deg);
    }

    //Hovering over the item
    .item:active,
    .item-title:focus {
      width: 28vw;
      filter: inherit;
      z-index: 5;
      transform: translateZ(calc(var(--index) * 10));
      margin: 0 0.45vw;
    }
  }
}

.grid-container {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 1rem;
  margin-top: 1rem;
  height: 72vh;

  .items {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-content: flex-start;
    width: 100%;
    overflow-y: auto;

    .item {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 17rem;
      height: 12rem;
      border-radius: 10px;
      border: 2px solid #273361;
      box-shadow: rgba(39, 51, 97, 0.4) -5px 5px,
        rgba(39, 51, 97, 0.3) -10px 10px, rgba(39, 51, 97, 0.2) -15px 15px,
        rgba(39, 51, 97, 0.1) -20px 20px, rgba(39, 51, 97, 0.05) -25px 25px;
      transition: box-shadow 0.15s ease-in-out;
      cursor: pointer;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      margin: 20px;

      .image-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      .item-title {
        position: absolute;
        bottom: 0;
        padding: 10px 0px;
        height: 15%;
        width: 100%;
        border-radius: 0px 0px 8px 8px;
        background-color: rgba(0, 0, 0, 0.5);
        color: white;
        .footer-title {
          display: block;
          color: #fff;
          padding: 2px 10px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 90%;
        }
      }
      .share-overlay {
        position: absolute;
        bottom: 0;
        right: 0;
        margin: 10px;
        height: 15%;
        width: 15%;
        color: white;
        text-align: center;
        fill: #fff;
        z-index: 2;
      }
    }
  }
}

.list-container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 72vh;

  .items {
    width: min(100%, 60rem);
    padding-inline: clamp(1rem, 100%, 25%);
    padding-top: 1rem;
    overflow-y: scroll;
    margin-inline: auto;
    perspective: 45rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    .item {
      position: relative;
      padding-inline: 2rem;
      background-repeat: no-repeat;
      background-size: 0%;
      transform-style: preserve-3d;
      color: var(--color);
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;
      padding: 1rem;
      border: 1px solid #273361;
      width: 70%;
      column-gap: 2rem;
      transition: transform s ease;

      &:hover {
        //Option 1
        //transform: scale(1.1) translateZ(10px);
        //Option 2
        // transform: matrix3d(
        //   0.9999999999999998,
        //   0,
        //   0,
        //   0,
        //   0,
        //   0.9999999999999998,
        //   0,
        //   0,
        //   0,
        //   0,
        //   1.1,
        //   0,
        //   0,
        //   0,
        //   0,
        //   1
        // );

        .item-title {
          .footer-title {
            color: #fff;
          }
        }

        .share-overlay {
          fill: #fff;
        }

        &::before,
        &::after {
          --side-rotate: 180deg;
        }
      }

      .item-title {
        width: 100%;
        .footer-title {
          display: block;
          color: #141414;
          padding: 2px 10px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 90%;
          z-index: 2;
        }
      }

      .share-overlay {
        cursor: pointer;
        z-index: 4;
        fill: #141414;
      }

      .image-overlay {
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 3;
      }
    }

    .item::before,
    .item::after {
      --side-rotate: 60deg;
      content: "";
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
      transform-origin: calc(50% - (50% * var(--ry))) 50%;
      transform: rotateY(calc(var(--side-rotate) * var(--ry)));
      background-color: inherit;
      background-image: linear-gradient(
        calc(9deg * var(--ry)),
        #273361,
        rgb(0 0 0 / 0.5)
      );
    }

    .item::before {
      --ry: -1;
      right: 100%;
    }

    .item::after {
      --ry: 1;
      left: 100%;
    }
  }
}

//darkmode
.darkmode {
  .grid-container {
    .items {
      .item {
        border: 2px solid #8dc3ea;
        box-shadow: rgba(141, 195, 234, 0.4) -5px 5px,
          rgba(141, 195, 234, 0.3) -10px 10px,
          rgba(141, 195, 234, 0.2) -15px 15px,
          rgba(141, 195, 234, 0.1) -20px 20px,
          rgba(141, 195, 234, 0.05) -25px 25px;
      }
    }
  }
  .list-container {
    .items {
      .item {
        border: 1px solid #8dc3ea;
        color: #fff;
        text-shadow: #8dc3ea 0px 0px 5px;
        .item-title {
          .footer-title {
            color: #fff;
          }
        }
        .share-overlay {
          fill: #fff;
          text-shadow: #8dc3ea 0px 0px 5px;
        }
      }
      .item::before,
      .item::after {
        background-image: linear-gradient(
          calc(9deg * var(--ry)),
          #8dc3ea,
          rgb(0 0 0 / 0.5)
        );
      }
    }
  }
}
