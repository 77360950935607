/* #photos_container {
    display: inline-block;
    height: calc(100vh - 60px);
    position: relative;
} */

#photos_grid {
  // padding-top: 14px;
  // padding-right: 20px;
  // padding-left: 20px;
  // padding-bottom: 28px;
  // overflow-y: auto;
  // max-height: calc(100vh - 120px);

  .cache_title {
    font-size: 1.5rem;
    font-weight: 500;
    padding-top: 20px;
  }

  .cache_message {
    padding: 15px 10px 50px 10px;
    width: 70%;
    font-size: 1.2rem;
  }

  .select_container {
    position: relative;
    z-index: 9;

    .icon {
      width: 1rem;
      transform: translate(0, -1px);
      margin-left: 10px;
      svg {
        fill: white;
      }
    }

    .select_all {
      position: absolute;
      left: -100px;
      transition: left 0.3s ease-in-out;
      margin: 0;
      padding: 0.25rem 0.5rem;
      line-height: 1.5;
      // no text wrap
      white-space: nowrap;
      border-radius: 0px 5px 5px 0px;
      top: 5px;
      &:hover {
        left: 0px;
      }
    }
    .deselect_all {
      position: absolute;
      left: -120px;
      transition: left 0.3s ease-in-out;
      margin: 0;
      padding: 0.25rem 0.5rem;
      line-height: 1.5;
      // no text wrap
      white-space: nowrap;
      border-radius: 0px 5px 5px 0px;
      top: 5px;
      &:hover {
        left: 0px;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  #photos_grid {
    max-height: calc(100vh - 170px);
  }

  @media screen and (max-width: 640px) {
    #photos_container {
      padding-left: 30px;
    }
  }
}

// when screen is 700 and less
@media screen and (max-width: 700px) {
  .select_all {
    top: 0px !important;
    justify-content: right;
    width: fit-content;
    right: -2px !important;
    left: auto !important;
    border-radius: 0px 0px 0px 5px !important;
    span {
      display: none;
    }
  }
  .icon {
    margin: 0px !important;
  }
  .deselect_all {
    top: 0px !important;
    justify-content: right;
    width: fit-content;
    left: auto !important;

    right: -2px !important;
    border-radius: 0px 0px 0px 5px !important;
    span {
      display: none;
    }
  }
}

#search_results_page_component_container {
  padding-top: 14px;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 28px;
  overflow-y: auto;
  max-height: calc(100vh - 120px);

  #photos_grid {
    max-height: none;
    padding: 0px;
  }

  #no_images_container {
    margin-left: -40px;
  }

  // #no_image_div {
  //   // margin-left: 10px;
  // }

  #search_results_page_component_container_inner {
    margin-top: 40px;
    padding-left: 0px;
  }

  .inner_top_flex {
    display: flex;
  }

  #search_results_koala_image {
    width: calc(30% - 6px);
    vertical-align: top;
    height: auto;
    object-fit: contain;
  }

  h2 {
    text-align: center;
    margin-left: -35px;
    // text-decoration: underline;
    font-weight: 400;
    margin: auto;
  }

  .keyword {
    justify-content: center;
    align-items: center;
    margin-bottom: 45px;

    #search_results_koala_image {
      width: 20%;
    }
  }
}
//Leaf Design
.total-display-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 50px;
  background-color: transparent;
  padding-right: 2rem;
  width: 96%;
  flex-wrap: wrap;
  column-gap: 5rem;

  .progress_outer_container {
    text-align: center !important;
  }

  .progress-label {
    display: flex;
    justify-content: end;
    margin-right: 30px;
    color: rgb(118, 115, 115);
    margin-bottom: -5px;
  }

  .progress_inner_container {
    display: flex;
    justify-content: start;
    align-items: center;

    .progress-percentage {
      margin-right: 1rem;
    }
    .progress-bar {
      height: 10px; /* Set the height of the progress bar */
      border-radius: 5px; /* Round the borders */
    }

    ::-webkit-progress-bar {
      background-color: #fff;
      border-radius: 5px;
      border: 1px solid #273361;
    }
    ::-webkit-progress-value {
      background-color: #273361;
      border-radius: 5px;
    }
    /* For Mozilla Firefox */
    ::-moz-progress-bar {
      background-color: #273361;
      border-radius: 5px;
    }
  }

  .leaf-container {
    position: relative;
    width: 100px;
    height: 150px;
  }

  .leaf {
    position: absolute;
    width: 100px;
    height: 200px;
  }

  .leaf-back {
    transform: scale(0.5);
    z-index: 1;
  }

  .leaf-front {
    transform: scale(0.5);
    z-index: 2;
    left: 5px; /* Adjust to place slightly in front */
  }
}

@media (max-width: 900px) {
  #search_results_page_component_container_inner_bottom {
    // padding-left: 20px;
    margin-top: 20px;
  }
}

@media (max-width: 640px) {
  #search_results_page_component_container_inner_bottom {
    padding-left: 30px;
    // margin-top: 20px;
  }
}

.darkmode {
  #search_results_page_component_container {
    h2 {
      color: white !important;
    }

    #images_grid .uk-card {
      box-shadow: 0px 0px 3px 0px #000000c7;
    }
  }
  .cache_title {
    color: white;
  }
  .cache_message {
    color: white;
  }
  .total-display-container {
    color: white;
    .total-display-text,
    .progress-percentage {
      color: white;
    }

    ::-webkit-progress-bar {
      background-color: #fff;
      border: 1px solid #8dc3ea;
    }
    ::-webkit-progress-value {
      background-color: #8dc3ea;
    }
    /* For Mozilla Firefox */
    ::-moz-progress-bar {
      background-color: #8dc3ea;
    }
  }
}

#search_results_koala_speech {
  // position: relative;
  // width: 70%;
  // height: auto;
  // min-height: 300px;
  // max-height: 300px;
  // top: 0px;
  // margin-top: 0px;
  background-color: #8dc3ea;
  border: 3px solid #8dc3ea;
  vertical-align: top;
  border-radius: 5px;
  padding: 30px;
  padding-right: 10px;
  width: 70%;
  max-height: 30vh;

  &:before {
    content: "";
    position: absolute;
    top: 30%;
    left: -75px;
    width: 20px;
    border-bottom: 20px solid transparent;
    border-right: 55px solid #8dc3ea;
    border-top: 20px solid transparent;
  }

  p {
    height: 100%;
    overflow-y: auto;
    padding-right: 30px;
  }
}

.smart-container {
  display: flex;
  margin-bottom: 20px;
  padding: 20px 35px 0px 30px;
  max-height: 40vh;

  .images_header {
    width: 30%;
    height: inherit;

    display: flex;
    justify-content: center;
  }
  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
}
