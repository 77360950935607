#main_header {
  position: relative;
  z-index: 1;

  /* MDI LOGO HERE: */

  .navmenulogo-bg {
    flex-wrap: wrap;
    max-height: 80px !important;
    display: flex;
    align-content: center;
    justify-content: center;
    background-color: #273361;
  }

  .editBoardContainer {
    position: relative;
  }

  .attributeDeleteContainer {
    position: relative;
  }

  .profileContainer {
    position: relative;
  }

  .createBoardContainer {
    position: relative;

    svg {
      fill: #8dc3ea;
    }
  }

  .logoSize {
    height: 80%;
    width: auto;
    object-fit: contain;
    cursor: pointer;
  }

  // Circle Logo (right)
  .circle_logo_container {
    border-radius: 50%;
    //overflow: hidden;
    //padding: 15px;
  }

  .logo_button {
    background-color: #fff !important;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 42px;
    width: 42px;
    min-height: 42px;
    min-width: 42px;
    border-radius: 500px;
    border: none;
    // color: rgba(251, 252, 252, 0.8);
    transition: background-color 0.2s ease-in-out;
    cursor: pointer;
  }

  /* BOARDS HERE */

  .navmenuboard-bg {
    padding: 0px !important;
    max-height: 80px !important;
    background-color: var(--main-color-text-light-blue);
    background-color: #273361;
  }

  .navMenuBoards_board {
    margin-top: auto !important;
    margin-bottom: auto !important;
    transition: all 0.2s ease-in-out;
    color: aliceblue;
  }

  /* css effect for hover and display of boards */

  .boardItems {
    margin: 0;
    padding: 0;
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
  }

  .boardItems .animationEffect {
    list-style: none;
    margin: 0px;
    transition: 0.5s;
    display: inline-block;
  }

  .boardItems::-webkit-scrollbar {
    visibility: hidden;
  }

  .arrow {
    cursor: pointer;
    user-select: none;
    /* Add your preferred styles here */
  }

  .boardItems .animationEffect {
    display: block;
    position: relative;
    text-decoration: none;
    padding: 5px;
    font-size: 1.4rem;
    color: #fff !important
    ;
    text-transform: uppercase;
    transition: 0.5s;
    border: 0.5px solid rgba(0, 0, 0, 0);
    //check padding with differnt fonts
    padding-bottom: 2px;
    padding-left: 8px;
    padding-right: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 280px;
  }

  .boardItems .navMenuBoards_board {
    padding-right: 0;
  }

  // changes from here
  .linkToBoards {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    display: inline-block;
    position: relative;
    text-decoration: none;
    padding: 5px;
    font-size: 1.4rem;
    color: #fff;
    text-transform: uppercase;
    transition: 0.5s;
    border: 0.5px solid rgba(0, 0, 0, 0);

    .scrolling-text {
      display: inline-block;
      position: relative;
      left: 100%;
      animation: scroll-text 10s linear infinite;
      color: #fff !important
      ;
    }

    @keyframes scroll-text {
      0% {
        left: 100%;
      }
      100% {
        left: -100%;
      }
    }
  }

  .navmenuprofile-bg {
    padding-right: 15px;
    padding-left: 15px;
    min-width: 250px;
    background-color: var(--main-color-text-light-blue);
    background-color: #273361;

    svg {
      fill: #8dc3ea;
    }
  }

  .re-import-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 15px;
  }

  .re-import-button {
    cursor: pointer;
    background-color: #8dc3ea;
    font-size: 1rem;
    margin-right: 0px;
    border-radius: 20px;
    border: none;
    height: 40px;
    width: 80px;
    display: flex;
  }

  .bulkIconCog {
    fill: #273361 !important;
    padding: 10px;
  }

  .navmenuprofile-bg .boardNameEditBtn {
    height: 72px !important;
    width: 68px !important;
    max-width: 80px !important;
    display: flex;
    align-content: center;
    justify-content: flex-end;
    cursor: pointer;
  }

  .navmenuprofile-bg .attributeDeleteBtn {
    height: 72px !important;
    width: 68px !important;
    max-width: 80px !important;
    display: flex;
    align-content: center;
    justify-content: flex-end;
    cursor: pointer;
  }

  .navmenuprofile-bg .profileSize {
    height: 72px !important;
    width: 68px !important;
    max-width: 80px !important;
    display: flex;
    align-content: center;
    justify-content: flex-end;
    cursor: pointer;
  }

  .navmenuprofile-bg .profileSize {
    height: 72px !important;
    width: 68px !important;
    max-width: 80px !important;
    display: flex;
    align-content: center;
    justify-content: flex-end;
    cursor: pointer;
  }

  //upload icon
  .navmenuprofile-bg .profileUpload {
    height: 78px !important;
    width: 90px !important;
    max-width: 100px !important;
    display: flex;
    align-content: center;
    justify-content: flex-end;
    cursor: pointer;
  }

  /* Board Deletion Confirmation */
  .button.swal2-confirm.fadedConfirmBoardDeletionButton.swal2-styled.swal2-default-outline {
    opacity: 0.5;
    cursor: not-allowed;
  }

  /* Profile Menu */

  .profileMenu {
    position: absolute;
    z-index: 6;
    background-color: #f4ffff;
    // border: 2px solid #273361;
    border: 2px solid #1e284d;
    border-radius: 5px;
    color: #273361;
    // top: 60px;
    right: 0px;
    padding: 15px;
    padding-left: 10px;
    padding-top: 10px;
    width: max-content;
  }

  #uploadIcon,
  #profileIcon {
    transition: all 0.1s ease-in-out;

    &:hover {
      filter: drop-shadow(0px 0px 4px #00000081) brightness(1.1);
    }

    &:active {
      filter: drop-shadow(0px 0px 5px #000000ce);
    }
  }

  // Profile dropdown

  .username {
    padding-top: 3px;
    padding-bottom: 10px;
    text-align: center;
  }

  .profileContent {
    user-select: none;
    -moz-user-select: none;
    -webkit-text-select: none;
    -webkit-user-select: none;
  }

  .profileContent:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  .profileMenu .iconContent {
    width: 20px;
    height: 20px;
    fill: var(--main-color);
  }

  .bulkMenu {
    position: absolute;
    z-index: 6;
    background-color: #ffffff;
    color: #000000;
    top: 60px;
    border: 2px solid var(--main-color-dark);
    // right: 166px;
    padding: 10px;
    padding-left: 5px;
  }

  .bulkIconOuter {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .bulkIconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50%;
    background-color: #8dc3ea;
    border-radius: 5px;
    cursor: pointer;
  }

  .bulkIconOuter_main {
    background-color: #273361;
    padding-left: 0px;
  }

  // .bulkIconContainer .textContent {
  //   // margin-bottom: 4px;
  // }

  // .bulkIconContainer img {
  // }

  // .bulkIconContainer span {
  //   // color: #fff;
  // }

  .bulkIconContainer .profileSize {
    width: 35px !important;
  }

  .bulkIconContainer .textContent {
    margin-left: 13px;
    height: 50%;
  }

  .bulkMenuContainer {
    padding: 0;
    margin: 0;
  }

  // All text except dark and light mode text
  .textContent {
    margin-left: 5px;
  }

  .custom_context_menu {
    position: absolute;
    // bottom: 25%;
    right: 100%;
    background-color: #f4ffff;
    border: 2px solid #273361;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    z-index: 2;
    width: 0px;
    font-size: 0.8em;
    width: 100%;
    transition: opacity 0.2s ease-in-out;
    overflow: hidden;
    padding: 2px;

    button {
      margin-top: 0px !important;
      margin-bottom: 0px !important;
      margin-left: 2px !important;
      margin-right: 2px !important;
      padding: 6px !important;
      padding-left: 8px !important;
      padding-right: 8px !important;
      color: var(--main-color-dark);
      border-radius: 10px;
      border: solid 1px;
      border-color: var(--main-color-text-light-blue);
      background-color: #8cc1e8;
      z-index: 1;
      cursor: pointer;

      &:hover {
        background-color: #5897c4;
      }
    }

    .heading {
      font-weight: 900;
      font-size: 1em;
      display: block;
      // text-shadow: 0 0 0px #082b3d;
      margin-bottom: 5px;
    }

    &.custom_context_menu_move {
      // bottom: 50%;
      top: 15%;
      padding: 10px;
      text-align: center;
    }

    &.custom_context_keyword {
      top: 25%;
      // bottom: 5%;
      padding: 10px;
      text-align: center !important;
    }

    &.custom_context_menu_delete {
      // top: 25%;
      // bottom: -2%;
      padding: 10px;
      text-align: center;
    }

    input {
      width: 80%;
      margin-bottom: 10px;
      border: 1px solid #27336159;
    }

    .custom_context_menu_item_button {
      cursor: pointer;
      transition: all 0.1s ease-in-out;
      text-align: left;

      &:hover {
        text-shadow: 0 0 1px #00000081;
      }

      img {
        height: 10px;
      }
    }
  }

  // DARK COLOUR MODE

  .darkmode {
    .navmenulogo-bg,
    .navmenuboard-bg,
    .navmenuboard-bg {
      background-color: var(--main-color);
    }
  }

  // Its working of the icon so right padding.
  .darkText {
    margin-right: 5px;
  }

  .lightText {
    margin-right: 5px;
  }

  .image_action_status_message {
    position: absolute;
    background-color: #eef6fc;
    z-index: 999999;
    top: 5px;
    right: 5px;
    border-radius: 5px;
    padding: 5px;
    font-size: initial;
  }

  #nav_toggler {
    display: none;
  }

  // Media queries for link to boards resizing

  @media screen and (max-width: 1550px) {
    .boardItems :nth-child(4) {
      display: none;
    }
  }

  @media screen and (max-width: 1800px) {
    .bulkActionText {
      display: none;
    }

    .bulkMenu {
      top: 37px !important;
    }

    .bulkIconOuter_main {
      background-color: #273361;
      position: absolute;
      right: 390px;
      top: 22px;
      z-index: 101;
    }
  }

  @media screen and (max-width: 1210px) {
    .boardItems :nth-child(3) {
      display: none;
    }
  }

  @media screen and (max-width: 850px) {
    & {
      height: 80px;
    }

    .boardItems .animationEffect .linkToBoards {
      font-size: 14px;
    }

    .profileMenu {
      width: auto;
      min-width: 160px;

      .profileContent {
        display: block;
      }
    }

    #nav_toggler {
      display: flex;
      position: fixed;
      right: 0px;
      z-index: 0;
      width: 25px;
      height: 79px;
      background-color: #273361;
      // padding: 25px;
      padding-right: 15px;
      cursor: pointer;

      svg {
        fill: #8dc3ea;

        &:active {
          fill: #5897c4;
        }
      }
    }

    .boardItems :nth-child(2),
    .boardItems :nth-child(3),
    .boardItems :nth-child(4) {
      display: block;
    }

    .boardItems .animationEffect {
      padding: 3px;
    }

    .navmenuprofile-bg {
      align-items: center;
      height: calc(100vh - 80px);
      flex-direction: column-reverse;
      justify-content: flex-end;
      align-items: center;
      min-width: auto !important;
      width: fit-content;

      .profileSize {
        height: 60px !important;
        padding: 8px !important;
      }

      .circle_logo_container {
        // padding: 8px !important;
        overflow: visible;
      }

      .logo_button {
        width: 35px;
        height: 35px;
      }
    }

    .navmenuboard-bg,
    .navmenuprofile-bg {
      position: fixed;
      max-height: unset !important;
      display: none;
    }

    .navmenuboard-bg {
      .boardItems {
        position: absolute;
        top: 20px;
        left: 50%;
        width: max-content !important;
      }
    }

    .navmenuprofile-bg {
      right: 0px;
      top: 80px;
      padding: 0px;
      border-top: none !important;
      border-left: 2px solid var(--main-color-dark);
    }

    .navmenulogo-bg {
      width: 100%;
      justify-content: flex-start;
    }

    &.navbar_dropdown_shown {
      z-index: 5;

      .navmenuboard-bg,
      .navmenuprofile-bg {
        z-index: 100 !important;
        border: 2px solid var(--main-color-dark);
        border-right: 0px;
      }

      .navmenuprofile-bg {
        display: inline-flex !important;
        border-bottom: 0px;
        margin-right: -2px;
      }

      .navmenuboard-bg {
        display: block !important;
        border: none;
        padding-left: 10px;
      }
    }

    .logoSize {
      margin-left: 20px;
    }

    .bulkIconOuter_main {
      background-color: #273361;
      position: absolute;
      right: 70px;
      top: 22px;
    }
  }
}

.darkmode {
  #main_header {
    .profileMenu {
      background-color: #2b396e;
      color: #ffffff !important;
      border: 2px solid var(--main-color-dark);
    }

    .profileMenu * {
      color: #ffffff !important;
    }

    .profileMenu .iconContent {
      fill: #ffffff;
    }
  }

  #swal-input1 {
    &::placeholder {
      color: #545454 !important;
    }
  }

  #swal-input2 {
    &::placeholder {
      color: #545454 !important;
    }
  }
}
