.upload_form_container form {
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 3px 0px 15px 0px #00000035;
  padding: 20px;

  .upload_icon {
    fill: #8cc1e8;
  }
}
.error-message {
  color: red;
  font-size: 12px;
}

.image_add_div {
  display: flex;
  justify-content: space-between;
}

.AddAttributeButton {
  max-width: 40px;
  margin-right: 50px !important;
  // margin-bottom: 50px !important;
  transform: scale(1.2);
}

.title {
  padding-left: 0px !important;
}

@media screen and (min-width: 768px) {
  .title {
    padding-left: 30px !important;
  }
}
