#profile_container_card {
  .uk-form-label {
    font-weight: bolder;

    @media screen and (min-width: 900px) {
      line-height: 40px;
    }
  }
}

.darkmode {
  #profile_container_card {
    background-color: #192244;
    color: #fff;
    h1 {
      margin-bottom: 0;
    }

    h1,
    p,
    label {
      color: #fff;
    }
  }
}
