/* BOARD GRID */
#clients_grid {
  padding-top: 14px;
  padding-right: 30px;
  padding-left: 30px;
  padding-bottom: 28px;
  overflow-y: auto;

  .uk-card {
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 71%;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 0px 3px 0px #979cab;
    transition: box-shadow 0.15s ease-in-out,
      background-position 0.05s cubic-bezier(1, 0.01, 0, 0.94);
  }

  .uk-card:hover {
    box-shadow: 0px 0px 5px 0px #000f3a;
  }

  .uk-card {
    margin-bottom: 0px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .client_div_container {
    padding: 5px !important;
    // width: 25%;
  }

  .pagination-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }

  .page-button {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  @media screen and (min-width: 1901px) {
    .imageSizing {
      width: 74%;
      height: auto;
    }
  }

  @media screen and (min-width: 300px) and (max-width: 1900px) {
    .imageSizing {
      width: 46%;
    }
  }

  @media screen and (max-width: 800px) {
    & {
      padding-left: 50px;
    }
  }
}
