// Boards //
#boards_grid {
  .uk-card {
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #1a2345; // Default dark blue background
    padding-top: 71%; // Maintains aspect ratio
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 0 3px 0 #979cab;
    transition: box-shadow 0.15s ease-in-out, background-color 0.2s ease,
      filter 0.2s ease-in-out;
    cursor: pointer;

    &:hover {
      box-shadow: 0 0 5px 0 #000f3a;
      filter: saturate(1.1) brightness(1.1);
      background-color: lighten(
        #1a2345,
        5%
      ); // Lightens the background on hover
    }
  }

  .uk-card-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(47, 55, 82, 0.85); // Translucent footer background
    color: white; // Ensures text color is consistently white
    padding: 5px;
    border-radius: 5px;
    overflow: hidden;

    .footer_title {
      flex: 1;
      margin-right: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: white;
      display: block;

      &:hover {
        animation: scrollTitle 10s linear forwards;
        overflow: visible;
      }
    }

    .share-icon {
      flex-shrink: 0;
      width: 20px;
      fill: white;
      cursor: pointer;

      svg {
        fill: white;
      }
    }
  }

  @keyframes scrollTitle {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-100% - 30px));
    } // Adjust "30px" to match the needed extra space
  }

  @media (min-width: 400px) and (max-width: 1300px) {
    .uk-card-footer .footer_title {
      font-size: 14px; // Smaller font size for narrower viewports
    }
  }

  @media screen and (min-width: 1901px) {
    .imageSizing {
      width: 74%;
      height: auto;
    }
  }

  @media screen and (min-width: 300px) and (max-width: 1900px) {
    .imageSizing {
      width: 46%;
    }
  }
}
