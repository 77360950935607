:root {
  --top-container-padding: 10%;
}

.search-container {
  width: 100%;
  display: flex;
  justify-content: center;
  .search-input {
    width: 95%;
  }
}
.attribute-container {
  // display: flex;
  justify-self: center;

  width: 100%;
  padding-bottom: 4em;

  h2 {
    padding-left: 40px;
  }

  .attributes-table {
    width: 95%;
    border-collapse: collapse;
    margin: 0 10px 0 10px;

    &,
    th,
    td {
      border: 2px solid #fff;
      padding: 8px;
      text-align: left;

      font-size: 1rem;
    }

    th {
      background-color: #1f8dc761;
      align-self: center;
      width: auto;
    }

    th:nth-child(1) {
      width: 75%;
    }

    th:nth-child(2) {
      width: 20%;
    }

    th:nth-child(3) {
      width: 5%;
    }

    .checkbox-column {
      text-align: center;
    }

    .td-icon {
      text-align: center;
      cursor: pointer;
    }
  }

  .footer-controls {
    position: fixed;
    bottom: 20px;
    right: 30px;
    display: flex;
    justify-content: space-between;
    width: 20%;
    height: 2rem;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 10px;
    border-radius: 10px;

    .btn-save {
      display: flex;
      justify-content: center;
      width: 50%;
      background-color: #4382b4;
      border-radius: 0px 10px 10px 0px;
    }

    .disabled {
      cursor: not-allowed;
      background-color: #4381b467;
    }
    .ready {
      cursor: pointer;
      color: white;
    }

    .btn-cancel {
      display: flex;
      justify-content: center;
      width: 50%;
      border-radius: 10px 0px 0px 10px;
    }
  }
}
.error-message {
  position: absolute;
  bottom: 0;
  right: 90%;
  width: 100%;
  border-radius: 10px;
  padding: 10px;
  background-color: #fff;
  z-index: 3;
  color: #d20a0a;
  font-size: 0.8rem;
  margin: 0 10px 0 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
}

.btn-save-floating {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 100; // Ensure the button is above other elements
}

.darkmode {
  .search-container {
    .search-input {
      background-color: #2a2e3f;
      color: #ffffff;
    }
  }
  .header_icon_container h2 {
    color: #ffffff;
  }
  .attributes-table {
    &,
    th,
    td {
      border: 1px solid #fff3; // Lighter border color for dark mode
      padding: 8px;
      text-align: left;
      color: #ffffff; // White text color for better readability in dark mode
      font-size: 1rem;
    }

    th {
      background-color: #1f8dc7a1; // Adjusted for dark mode
    }
  }

  .no-data {
    color: #ffffff;
  }

  .smart-name-input {
    background-color: #2a2e3f;
    color: #ffffff;
  }

  th {
    background-color: #1f8dc761;
    align-self: center;
    width: auto;
    color: #ffffff;
  }

  .checkbox-column {
    text-align: center;
  }

  .td-icon .td {
    padding: 0px !important;
  }

  .error-message {
    position: absolute;
    bottom: 0;
    right: 90%;
    width: 100%;
    border-radius: 10px;
    padding: 10px;
    background-color: #f7f3f3;
    z-index: 3;
    color: #d20a0a;
    font-size: 0.8rem;
    margin: 0 10px 0 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  }
  .footer-controls {
    background-color: #2a2e3f;
    .btn-save {
      background-color: #4382b4;
      color: white;

      &:hover {
        background-color: #6fb8ee;
        color: white;
      }
    }
    .btn-cancel {
      background-color: #ffffff1c;
      color: white;

      &:hover {
        //background-color: #6fb8ee;
        color: white;
      }
    }
  }
  span.uk-icon.uk-spinner svg circle {
    color: white !important;
  }
}
