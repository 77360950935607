/* BOARD GRID */
.filter-container {
  margin: 1.1rem;
  border-radius: 10px;
  padding: 20px;
  background-color: #273361;

  .filter-title {
    font-size: 1rem;
    font-weight: 600;
    color: #fff;
    position: relative;
    width: fit-content;
  }

  .filter-title::after {
    position: absolute;
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    background-color: #fff;
  }

  .filter-options {
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .search {
      width: 60%;
      padding: 10px;
      border-radius: 10px;
      border: 1px solid #fff;
      //color: #000f3a;
      color: #000f3a;
    }

    .search::placeholder {
      color: #000f3a !important;
      font-family: "Comfortaa";
    }

    .project-filter {
      width: 20%;
      border-radius: 10px;
      background-color: #fff;

      option {
        color: #000f3a;
      }
    }
  }
}

.darkmode {
  .filter-container {
    .search {
      color: #fff !important;
      background-color: #273361;
    }
    .search::placeholder {
      //color: #000f3a !important;
      color: #fff !important;
    }

    .project-filter {
      background-color: #273361;
      color: #fff;

      option {
        color: #fff;
      }
    }
  }
}

#boards_grid {
  padding: 2vh;
  overflow-y: auto;
  margin-left: 0px;
  margin-top: 0vh;
  max-height: 70vh;

  .uk-card {
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 71%;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 0px 3px 0px #979cab;
    transition: box-shadow 0.15s ease-in-out,
      background-position 0.05s cubic-bezier(1, 0.01, 0, 0.94);
  }

  .uk-card:hover {
    box-shadow: 0px 0px 5px 0px #000f3a;
  }

  .uk-card {
    margin-bottom: 0px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .board_div_container {
    padding: 5px !important;
  }

  .no_boards_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    .no_boards {
      margin-top: 20px;
      margin-bottom: 20px;
      text-align: center;
      font-size: 1.2em;
      font-weight: 900;
      color: #273361;
    }
  }

  @media screen and (min-width: 1901px) {
    .imageSizing {
      width: 74%;
      height: auto;
    }
  }

  @media screen and (min-width: 300px) and (max-width: 1900px) {
    .imageSizing {
      width: 46%;
    }
  }

  @media screen and (max-width: 800px) {
    & {
      //padding-left: 50px;
    }
  }
}
