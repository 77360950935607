.background {
  position: relative;
  height: 500px;
  width: 500px;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: inherit;
    filter: blur(10px);
  }

  .branch {
    background-color: #7b5319;
    width: 90%;
    height: 6%;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 12px;
  }

  .leaves {
    position: absolute;
    content: "";
    height: 5%;
    width: 7%;
    background-color: #768b36;
    top: 52%;
    left: 70%;
    border-radius: 30px 0;
  }

  .leaves:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: #768b36;
    top: 10%;
    left: 70%;
    border-radius: 0 30px;
    transform: rotate(30deg);
    animation: fallAndGrow 5s infinite;
  }

  @keyframes fallAndGrow {
    0% {
      top: 10%;
      opacity: 1;
    }
    50% {
      top: 90%;
      opacity: 0;
    }
    100% {
      top: 10%;
      opacity: 0;
    }
  }

  .textLoader {
    content: "z";
    position: absolute;
    top: 10%;
    left: 15%;
    transform: translate(-50%, -50%);
    font-size: 2rem;
    font-weight: bold;
    color: #fff;
    height: 20px;
    width: 70%;
    transform: rotate(-10deg);
  }

  .textLoader::after {
    content: "z";
    animation: textLoader 8s infinite;
  }

  @keyframes textLoader {
    0% {
      content: "z";
    }
    10% {
      content: "z Z";
    }
    20% {
      content: "z Z Z";
    }
    30% {
      content: "z Z Z Z";
    }
    40% {
      content: "z Z Z Z Z";
    }
    50% {
      content: "z Z Z Z Z Z";
    }
    60% {
      content: "z Z Z Z Z Z";
    }
    70% {
      content: "z Z Z Z Z";
    }
    80% {
      content: "z Z Z Z";
    }
    90% {
      content: "z Z Z";
    }
    100% {
      content: "z Z";
    }
  }

  .koalaBody {
    background-color: #767677;
    height: 20%;
    width: 30%;
    position: absolute;
    top: 30%;
    left: 25%;
    border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%;
    cursor: grab;
    animation: movingBody 5s infinite;
  }

  @keyframes movingBody {
    0% {
      transform: scale(1.02);
    }
    25% {
      transform: scale(1.04);
    }
    50% {
      transform: scale(1.08);
    }
    75% {
      transform: scale(1.04);
    }
    100% {
      transform: scale(1.02);
    }
  }

  .tail {
    position: absolute;
    content: "";
    height: 3%;
    width: 3%;
    background-color: #aaa7aa;
    top: 38%;
    left: 54%;
    border-radius: 50%;
  }

  .koalaBody::before,
  .koalaBody::after {
    content: "";
    position: absolute;
    background-color: #767677;
    height: 60%;
    width: 20%;
    border-radius: 18px;
  }
  //Back leg
  .koalaBody::before {
    top: 70%;
    left: 75%;
    transform: rotate(-15deg);
  }
  //Front leg
  .koalaBody::after {
    top: 70%;
    left: 5%;
    transform: rotate(0deg);
  }

  .koalaHead {
    background-color: #8f8d8f;
    height: 20%;
    width: 20%;
    position: absolute;
    top: 30%;
    left: 15%;
    border-radius: 50%;
    cursor: grab;
    //Nose
    .faceDetails {
      content: "";
      position: absolute;
      background-color: #343434;
      height: 40%;
      width: 35%;
      left: 37%;
      top: 40%;
      transform: rotate(-30deg);
      border-radius: 50% 50% 50% 50%/ 60% 60% 40% 40%;
    }
    //Eye one
    .faceDetails:before {
      content: "";
      position: absolute;
      height: 20%;
      width: 50%;
      border-radius: 50%;
      box-shadow: 0 3.5px #343434;
      left: 100%;
    }
    //Eye two
    .faceDetails:after {
      content: "";
      position: absolute;
      height: 20%;
      width: 50%;
      box-shadow: 0 3.5px #343434;
      border-radius: 50%;
      right: 100%;
    }

    .faceDetails.openingEyes:after {
      /* Styles for open eyes */
      content: "";
      position: absolute;
      height: 20%;
      width: 50%;
      box-shadow: 0 3.5px #343434;
      border-radius: 50%;
      right: 100%;
      // animation: wakingUp 10s;
    }

    @keyframes wakingUp {
      0% {
        content: "";
        position: absolute;
        height: 50%;
        width: 50%;
        border-radius: 50%;
        box-shadow: 0 0px #343434;
        right: 100%;
        background-color: #343434;
      }
      10% {
        content: "";
        position: absolute;
        height: 50%;
        width: 50%;
        border-radius: 50%;
        right: 100%;
        background-color: #343434;
      }
      20% {
        content: "";
        position: absolute;
        height: 50%;
        width: 60%;
        border-radius: 50%;
        right: 100%;
        background-color: 343434;
      }
      30% {
        content: "";
        position: absolute;
        height: 50%;
        width: 60%;
        border-radius: 50%;
        right: 100%;
        background-color: 343434;
      }
      40% {
        content: "";
        position: absolute;
        height: 30%;
        width: 60%;
        border-radius: 50%;
        right: 100%;
        background-color: 343434;
      }
      50% {
        content: "";
        position: absolute;
        height: 30%;
        width: 60%;
        border-radius: 50%;
        right: 100%;
        background-color: 343434;
      }
      60% {
        content: "";
        position: absolute;
        height: 30%;
        width: 60%;
        border-radius: 50%;
        right: 100%;
        background-color: 343434;
      }
      70% {
        content: "";
        position: absolute;
        height: 30%;
        width: 60%;
        border-radius: 50%;
        right: 100%;
        background-color: 343434;
      }
      80% {
        content: "";
        position: absolute;
        height: 30%;
        width: 60%;
        border-radius: 50%;
        right: 100%;
        background-color: 343434;
      }
      90% {
        content: "";
        position: absolute;
        height: 30%;
        width: 60%;
        border-radius: 50%;
        right: 100%;
        background-color: 343434;
      }
      100% {
        content: "";
        position: absolute;
        height: 20%;
        width: 50%;
        box-shadow: 0 3px #343434;
        border-radius: 50%;
        right: 100%;
        background-color: #343434;
      }
    }
  }

  .koalaEar-1,
  .koalaEar-2 {
    background-color: #efd8db;
    position: absolute;
    top: 0;
    left: 0;
    height: 8%;
    width: 8%;
    border-radius: 50%;
  }

  .koalaEar-1 {
    box-shadow: -3px -5px 0 12px #a9a9ac;
    top: 40%;
    left: 10%;
  }

  .koalaEar-2 {
    box-shadow: 0 -5px 0 12px #a9a9ac;
    top: 28%;
    left: 30%;
  }
  //this will be for it legs
  .koalaBackLeg-1,
  .koalaBackLeg-2 {
    content: "";
    position: absolute;
    background-color: #5c5b5d;
    height: 15%;
    width: 6%;
    border-radius: 18px;
  }

  .koalaBackLeg-1 {
    top: 42%;
    left: 18%;
    transform: rotate(0deg);
  }
  .koalaBackLeg-2 {
    top: 42%;
    left: 40%;
    transform: rotate(0deg);
  }
}
