/* PHOTO GRID */
#photos_grid {
  .uk-card {
    background: {
      repeat: no-repeat;
      size: cover;
      color: #1a2345;
    }

    margin: {
      bottom: 9px !important;
      left: 0px !important;
      right: 0px !important;
    }

    padding: {
      left: 0px !important;
      right: 0px !important;
      top: 71%;
    }

    position: relative;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0px 0px 3px 0px #979cab;
    transition: all 0.2s ease-in-out, box-shadow 0.15s ease-in-out,
      background-position 0.05s cubic-bezier(1, 0.01, 0, 0.94),
      filter 0.2s ease-in-out, transform 0.2s ease;
    filter: saturate(0.9) brightness(0.9);

    &:hover {
      box-shadow: 0px 0px 5px 0px #000f3a;
      filter: saturate(1.1) brightness(1.1);
    }

    &.card_side_menu_shown {
      background-position: right 70px top 0px;
      transition: background-position 0.1s ease-in-out;

      .uk-card-footer {
        background-color: #2f3752;
      }
    }
  }

  .uk-card-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    box-sizing: border-box;
    background: rgba(47, 55, 82, 0.85);
    color: white;
    text-align: center;
    /* height: 30%; */
    border: none;
    padding: 10px !important;
    padding-top: 0 !important;
    border-radius: 5px;
    overflow: hidden;

    .footer_title,
    .footer_description {
      color: white !important;
      display: block;
    }

    .footer_title {
      font-size: 16px;
      font-weight: 100;
      margin: 0;
      padding: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .footer_description {
      width: 95%;
      font-size: 0.8rem;
      font-weight: 300;
      margin: 0;
      padding: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  @media (min-width: 400px) and (max-width: 1300px) {
    .uk-card-footer .footer_title {
      font-size: 16px !important;
    }
  }

  .photo_div_container {
    padding: 5px !important;

    ::-webkit-scrollbar {
      background-color: var(--scrollbar-background-color-dark);
      width: 0.8em;
      border-radius: 8px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: var(--scrollbar-thumb-color-dark);
      outline: none;
      border-radius: 8px;
    }

    img,
    .iconsNavMenu {
      cursor: pointer;
    }
  }

  .info_button_container {
    position: absolute;
    width: 70px;
    height: 70px;
    right: 0;
    bottom: 0;
    background: radial-gradient(circle at top left, transparent 70px, #1a2345 0)
      top left;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    z-index: 1;

    .info_button {
      position: absolute;
      bottom: 0;
      right: 15%;
      color: white !important;
    }
  }

  .card_side_menu {
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 100%;
    background-color: #1a2345;
    transition: opacity 0s ease-out;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;

    .card_side_menu_item {
      color: white !important;
      text-align: center;

      img {
        cursor: pointer;
      }
    }
  }

  .card_side_menu_shown {
    .info_button_container {
      background: #1a2345;
    }

    .card_side_menu {
      opacity: 1;
    }

    .info_button_container {
      height: 100%;
    }
  }

  .card_side_menu_hidden {
    .card_side_menu {
      opacity: 0;
    }
  }

  .card_full_info {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #2f3752;
    display: none;
    opacity: 0;
    z-index: -1;
  }

  .card_full_info_inner span {
    color: white !important;
  }

  .card_full_info_inner {
    padding-top: 4%;
    padding-bottom: 4%;
    padding-right: 10%;
    padding-left: 10%;

    span {
      display: block;
    }

    .card_full_info_inner_keywords {
      margin-bottom: 10px;
    }

    .card_full_info_inner_title {
      font-weight: 600;
      font-size: 1.2rem;
    }

    .card_full_info_inner_keywords_title {
      font-weight: 600;
    }
  }

  .card_footer_hovered .card_full_info {
    display: block;
    opacity: 1;
    z-index: 1;
    transition: opacity 0.2s ease-in-out;
  }

  .info_button {
    font-family: "DancingScript" !important;
    font-size: 1.2em;
  }

  .topGap {
    margin-top: 0.625em;
  }

  .imageGaps {
    margin-bottom: 1.125em;
  }

  .flip {
    transform: rotateY(180deg);
  }

  // .card_side_menu_item {
  //   position: relative;
  // }

  .custom_context_menu {
    position: absolute;
    top: 20%;
    right: 60px;
    // background-color: rgba(223, 236, 239, 0.5);
    background-color: #dfecef;

    border: 2px solid #273361;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    z-index: 2;
    width: 0px;
    // font-size: 0.8em;
    width: 220%;
    transition: opacity 0.2s ease-in-out;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 300%;
    padding: 2px;

    .no_resource {
      font-size: 0.8rem !important;
    }

    span {
      font-size: 0.8rem;
      font-weight: 600;
      margin: 0;
      padding: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      display: inline-block;
      width: 97%;
    }

    button {
      margin-top: 0px !important;
      margin-bottom: 0px !important;
      margin-left: 2px !important;
      margin-right: 2px !important;
      padding: 6px !important;
      padding-left: 8px !important;
      padding-right: 8px !important;
      color: var(--main-color-dark);
      border-radius: 10px;
      border: solid 1px;
      border-color: var(--main-color-text-light-blue);
      background-color: #8cc1e8;
      z-index: 1;
      cursor: pointer;

      &:hover {
        background-color: #5897c4;
      }
    }

    .heading {
      font-weight: 900;
      font-size: 0.8em;
      display: block;
      // text-shadow: 0 0 0px #082b3d;
      margin-bottom: 5px;
    }

    &#custom_context_menu_pin {
      padding: 10px;

      .pinItem {
        margin-right: 2px;
      }
    }

    &.custom_context_menu_delete {
      top: initial;
      bottom: 20%;
      padding: 10px;
    }

    &.custom_context_menu_folder {
      bottom: 20%;
      top: initial;
      padding: 10px;
      width: auto;
      min-width: 150px;

      input {
        width: 100px;
        margin-bottom: 10px;
        border: 1px solid #27336159;
      }
    }

    .custom_context_menu_item_button {
      cursor: pointer;
      transition: all 0.1s ease-in-out;
      text-align: left;

      &:hover {
        text-shadow: 0 0 1px #00000081;
      }

      img {
        height: 10px;
      }
    }
  }

  /* The x and expand button on the full card info */

  .backgroundX {
    width: 100%;
    padding-bottom: 0%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    /* Align items to the right side */
  }

  .iconsNavMenu {
    width: 10%;
    padding-top: 2%;
    padding-bottom: 2%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--main-color);
    transition: background-color 0.1s ease-in-out;
    opacity: 1;
    transform: scale(0.75);
    transition: transform 0.2s ease;

    &:hover {
      // background-color: var(--main-color-text-light-blue);
      background-color: var(--main-color-text-light-blue);
    }
  }

  .iconSizesNav.invert {
    filter: invert(100%);
  }

  .iconSizesNav {
    width: 40%;
    height: auto;
  }

  .card_checkbox_container {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 25%;
    height: 25%;
    opacity: 0;
    transition: opacity 0.1s ease;
  }

  .card_checkbox {
    appearance: none !important;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    outline: none;
    background-color: transparent;
    width: 14px !important;
    height: 14px !important;
    border: 2.5px solid #fff;
    border-radius: 50%;
    background-color: #fff;
    margin: 3px;
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1;
    cursor: pointer;
  }

  .card_checkbox:checked {
    /* Style when the checkbox is checked */
    background-color: #1f8dc7;
    // border-color: #1f8dc7;
  }

  .card_checkbox_container.visible,
  .card_checkbox_container:hover,
  .image_clickable:hover + .card_checkbox_container,
  .image_clickable:hover,
  .any-checkbox-selected .card_checkbox_container {
    opacity: 1;
  }

  .card_image_container.selected {
    transform: scale(0.85);
    // border: 1px solid #1f8dc7;
    // outline: 1px solid #1f8dc7;
    box-shadow: 12px 12px 12px #1f8dc7;

    filter: none !important;

    &:hover {
      box-shadow: 12px 12px 12px #186f9e;
    }
  }

  .image_clickable {
    position: absolute;
    width: 100%;
    height: 78%;
    top: 0;
    z-index: -11;
  }

  #image_action_status_message {
    position: absolute;
    background-color: #eef6fc;
    z-index: 999999;
    top: 5px;
    right: 5px;
    border-radius: 5px;
    padding: 5px;
    font-size: initial;
  }

  @media screen and (min-width: 1901px) {
    .imageSizing {
      width: 74%;
      height: auto;
    }
  }

  @media screen and (max-width: 1900px) {
    .imageSizing {
      width: 70%;
    }

    .card_side_menu {
      padding-top: 0px;
    }
  }

  @media screen and (max-width: 1700px) {
    .imageSizing {
      width: 65%;
    }

    .card_side_menu {
      padding-top: 0px;
    }
  }

  @media screen and (max-width: 1480px) {
    .imageSizing {
      width: 55%;
    }

    .card_side_menu {
      padding-top: 0px;
    }
  }

  @media screen and (max-width: 1300px) {
    .imageSizing {
      width: 45%;
    }

    .card_side_menu {
      padding-top: 0px;
    }

    .imageGaps {
      margin-bottom: 15px;
    }
  }

  @media screen and (max-width: 1200px) {
    .imageSizing {
      width: 60%;
    }

    .card_side_menu {
      padding-top: 0px;
    }

    .imageGaps {
      margin-bottom: 15px;
    }
  }

  @media screen and (max-width: 1000px) {
    .imageSizing {
      width: 50%;
    }

    .card_side_menu {
      padding-top: 0px;
    }

    .imageGaps {
      margin-bottom: 10px;
    }
  }

  @media screen and (max-width: 960px) {
    .imageSizing {
      width: 60%;
    }

    .card_side_menu {
      padding-top: 0px;
    }

    .imageGaps {
      margin-bottom: 10px;
    }
  }

  @media screen and (max-width: 900px) {
    .imageSizing {
      width: 60%;
    }

    .card_side_menu {
      padding-top: 0px;
    }

    .topGap {
      margin-top: 3px;
    }

    .imageGaps {
      margin-bottom: 3px;
    }

    .card_side_menu,
    .info_button_container {
      width: 40px;
    }

    .uk-card.card_side_menu_shown {
      background-position: right 60px top 0px;
    }

    .iconsNavMenu {
      width: 30px;
    }

    .uk-card-footer .footer_description {
      display: none !important;
    }

    .custom_context_menu {
      top: 0px !important;
      right: 55% !important;
    }

    .image_clickable {
      height: 85%;
    }
  }
}

.darkmode {
  #photos_grid {
    .uk-card {
      box-shadow: 0px 0px 3px 0px #000000c7;
    }

    .card_image_container.selected {
      transform: scale(0.85);
      // border: 1px solid #1f8dc7;
      // outline: 1px solid #1f8dc7;
      box-shadow: 12px 12px 12px #1f8dc7;
    }
  }
}

@media screen and (max-width: 900px) {
  #photos_grid {
    padding-left: 2px !important;
    padding-right: 2px !important;

    .photo_div_container {
      padding: 2px !important;

      .uk-card {
        margin-bottom: 2px !important;
        padding-top: 80%;

        .card_side_menu_item {
          margin-left: -7px !important;
          margin-right: -7px !important;
          margin-bottom: 4px !important;
        }

        .custom_context_menu {
          right: 40px !important;
          padding: 5px !important;
          max-height: 550%;

          &.custom_context_menu_folder,
          &.custom_context_menu_delete {
            top: -100% !important;
          }

          .heading {
            display: block !important;
          }

          input {
            display: block !important;
          }

          min-width: auto !important;
        }
      }
    }

    .card_side_menu,
    .info_button_container {
      width: 30px;
      padding-left: 5px;
      padding-right: 5px;
    }

    .uk-card.card_side_menu_shown {
      background-position: right 40px top 0px;
    }

    #image_action_status_message {
      font-size: 10px !important;
    }
  }
}

@media screen and (max-width: 480px) {
  .custom_context_menu {
    max-height: 350% !important;
  }
}
