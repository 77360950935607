.side-panel {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100svh;
  background-color: #27336180;
  transition: transform 0.3s ease-in-out;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  z-index: 14;
  padding: 0px !important;

  .side-panel-container {
    width: 100%;
    height: 100%;
    display: flex;
    &.left {
      justify-content: flex-start;
      .swap-btn {
        display: flex;
        justify-content: flex-end;
        background-color: #273361;
        border: none;
        color: white;
        font-size: 16px;
        cursor: pointer;
      }
    }
    &.right {
      justify-content: flex-end;

      .swap-btn {
        display: flex;
        justify-content: flex-start;
        background-color: #273361;
        border: none;
        color: white;
        font-size: 16px;
        cursor: pointer;
      }
    }

    .side-bar {
      width: 70%;
      height: 100%;
      background-color: #273361;
      padding: 10px;

      @media (max-width: 700px) {
        width: 100%;
      }

      .title-bar {
        width: 100%;
        height: 10%;

        .content-header {
          display: flex;
          color: white;
          margin: 10px;
        }

        // Close button styling
        .close-btn {
          position: absolute;
          top: 10px;
          right: 10px;
          background-color: transparent;
          border: none;
          font-size: 24px;
          cursor: pointer;
          color: white;
        }
      }

      .content {
        display: flex;
      }
    }

    .modal-loader span.uk-icon.uk-spinner svg circle {
      // fill: white !important;
      color: white !important;
    }
  }

  // To animate the panel from off-screen to on-screen
  .side-panel.show {
    transform: translateX(0); // Move to show on screen
  }
}
