/*Styling for the Home Dashboard page*/
.page-container {
  display: flex;
  justify-content: space-between;
  max-width: 97%;
  margin: auto; // Center the container
  border: 1px solid #ccc;
  border-radius: 30px;
  background-color: lightblue;
  margin-top: 20px;
}

.left-side {
  width: 100%; // Adjust width for gutters
  padding: 2vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%; // Set height to 100% to fill the container
  height: 80vh; // Set a max height to trigger scrolling
}

.client-title {
  align-self: center;
  margin-top: 3vh;
}

.right-side {
  background-color: #f9f9f9;
}

// .grid {
//   display: grid;
//   grid-template-columns: repeat(4, 1fr); // Change for number of columns
//   grid-gap: 10px;
//   margin-top: 2vh;
//   overflow-y: auto; // Enable vertical scrolling
//   max-height: 60vh;
// }

.grid-item-left-side {
  border: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  height: auto;
  min-height: 16vh;
  background-size: cover;
  background-position: center;
  flex-direction: column;
  justify-content: flex-end;
  max-width: 100%;
  max-height: 100%;
  position: relative;
  color: white;
  object-fit: contain;
}

.client-grid {
  margin-left: 20px;
  margin-right: 0px;
  padding-right: 0px;
}

.viewStatisticsButton:hover {
  background-color: black;
}

.client-title-container {
  display: flex;
}

.client-picture-container {
  display: flex;
}

.single-client-attribute-table-container {
  display: flex;
}

#boards_container {
  background-color: lightblue !important;
  border: 1px solid #ccc;
  border-radius: 30px;
  margin-top: 20px;
  margin-left: 10px;
  max-width: 97%;
  max-height: 80vh;
  height: 80vh; // Set a max height to trigger scrolling

  .board_header {
    align-items: center;
    justify-self: center;

    .board-title {
      justify-self: center;
      text-align: center;
      margin-top: 4.5vh;
    }
  }
}
