// .navmenulogo-bg {
//   width: 100%;
//   justify-content: flex-start;
// }

.navbar-shared {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  background-color: #273361;
  .logoSize {
    margin: 20px;
    height: 80px;
  }
}

.shared-board-page {
  height: calc(100vh);
  margin-bottom: 20px;
}
