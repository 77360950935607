.sidebar {
  margin-top: 0px;
  width: 100%;
  display: inline-block;
  height: calc(100vh - 80px);
  box-shadow: 3px 0px 15px 0px #00000063;
  background-color: #dfecef;
  z-index: 10;

  .sidebar-headers {
    display: flex;
    justify-content: space-between;
    margin: 25px 25px 0px 25px !important;

    .search-wrapper {
      position: relative;
      width: 30%;
      background-color: #65666636;
      border-radius: 5px 5px 0px 0px;

      .attributes-search-wrapper {
        position: relative;
        width: 100%;

        .search-icon {
          width: 100%;
          height: 40px;
          padding: 10px;
          cursor: pointer;
          fill: #1f8dc7 !important;
          //border: 1px solid #1f8dc7;
          background-color: #65666636;
          border-radius: 5px 5px 0px 0px;

          &.selected {
            //box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
            background-color: #ffffff;
            border-radius: 5px 5px 0px 0px;
            z-index: 1;
          }

          .g {
            width: 10px;
          }
        }
      }
    }

    .filter-explain {
      width: 100%;
      height: 50px;
      fill: #1f8dc7;

      position: absolute;
      bottom: -100%; /* Initially position it off-screen or behind */
      left: 0;
      opacity: 0;
      transition: all 0.3s ease;
      cursor: pointer; /* Smooth transition for the slide-up effect */
    }

    .attributes-search-wrapper:hover .filter-explain {
      bottom: 45px; /* Position it to slide up */
      opacity: 1; /* Make it visible */
    }

    .header-icons {
      width: 30%;
      height: 40px;
      padding: 10px;
      cursor: pointer;
      fill: #1f8dc7 !important;
      //border: 1px solid #1f8dc7;
      background-color: #65666636;
      border-radius: 5px 5px 0px 0px;

      &.selected {
        //box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        background-color: #ffffff;
        border-radius: 5px 5px 0px 0px;
        z-index: 1;
      }

      .g {
        width: 10px;
      }
    }
  }

  #sidebar_section_search {
    height: 100%;
    // width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin: 0px 25px 35px 25px !important;
  }

  #sidebar_section_search .tagArea {
    height: 20% !important;
  }

  #sidebar_section_search .attributes {
    height: 80% !important;
  }

  .tagArea {
    color: var(--main-color);
    // border: 2px solid #1f8dc7;
    box-shadow: 3px 0px 15px 0px #00000035;
    border: none;
    border-radius: 0px 0px 10px 10px;
    width: 100% !important;
    height: 100% !important;
    background-color: #ffffff;
    padding: 15px 0px 15px 5px;
    box-sizing: border-box;
    margin-bottom: 0 !important;
    resize: none;
    text-align: center;
    // overflow-y: auto;
    // overflow-x: hidden;
  }

  .textArea {
    color: var(--main-color);
    // border: 2px solid #1f8dc7;
    box-shadow: 3px 0px 15px 0px #00000035;
    border: none;
    border-radius: 10px;
    width: 100% !important;
    height: 100% !important;
    background-color: #ffffff;
    padding: 0px 0px 40px 10px;
    box-sizing: border-box;
    margin-bottom: 0 !important;
    resize: none;
    text-align: center;
    // overflow-y: auto;
    // overflow-x: hidden;
  }

  .textAreaInput {
    color: var(--main-color);
    // border: 2px solid #1f8dc7;

    border: none;
    border-radius: 10px;
    width: 100% !important;
    height: 100% !important;
    background-color: #ffffff;
    padding: 20px 0px 40px 0px;
    box-sizing: border-box;
    margin-bottom: 0 !important;
    resize: none;
    text-align: start;
    // overflow-y: auto;
    // overflow-x: hidden;
  }

  .textAreaInput::placeholder {
    text-align: center;
    margin-right: 10px;
  }

  // .textArea::placeholder {
  //   font-size: 1rem;
  //   opacity: 1;
  //   color: var(--main-color);
  //   text-align: center !important;
  // }

  .tagsContainer {
    height: calc(100% - 50px);
    width: calc(100% - 13px);
    overflow-y: auto;
    padding-left: 10px;
  }

  .tag {
    float: left;
  }

  .inputSearch {
    width: calc(100% - 5px);
    text-align: start;
    border: none;
  }

  .inputSearch::placeholder {
    text-align: center;
    margin-right: 5px;
  }

  span.tag {
    background-color: #65bbe7;
    padding-top: 1px;
    padding-left: 4px;
    padding-right: 4px;
    border-radius: 5px;
    margin-right: 5px;
    margin-top: 4px;
    margin-bottom: 4px;
    display: inline-block;
    padding-bottom: 2px;
    color: white;
    font-size: 0.8em;
    .closeTag {
      background: transparent;
      cursor: pointer;
      color: white;
      border: none;
    }
  }

  .searchContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
  }

  .searchGo {
    margin-top: 5px;
    margin-left: 15px;
    margin-bottom: 5px;
    color: var(--main-color-dark);
    border-radius: 10px;
    border: solid 1px;
    border-color: var(--main-color-text-light-blue);
    width: 40px;
    height: 21px;
    background-color: var(--main-color-text-light-blue);
    cursor: pointer;
  }

  .searchGo:hover {
    border: solid 1px;
    border-color: var(--main-color-light);
    background-color: var(--main-color-light);
  }

  .dashButton {
    margin-bottom: 0px !important;
    padding-bottom: 0px !important;
    color: var(--main-color-dark);
    border-radius: 10px;
    border: solid 1px;
    border-color: var(--main-color-text-light-blue);
    width: 40px;
    height: 21px;
    background-color: var(--main-color-text-light-blue);
    position: absolute;
    top: initial;
    right: 15px;
    bottom: 15px;
    z-index: 1;
    cursor: pointer;
  }

  .dashButton:hover {
    border: solid 1px;
    border-color: var(--main-color-light);
    background-color: var(--main-color-light);
  }

  /* filter area */
  #sidebar_section_filter {
    height: 100%;
    display: flex;
    justify-content: start;
    align-items: flex-start;
    flex-flow: column;
    margin: 20px 25px 25px 25px !important;
  }

  .side_filter_container {
    background-color: #ffffff;
    width: 100%;
    height: 80%;
    padding: 2%;
    //border: 2px solid #1f8dc7;
    box-shadow: 3px 0px 15px 0px #00000035;
    border: none;
    border-radius: 0px 0px 10px 10px;
    box-sizing: border-box;
  }

  .side_filter_container_inner {
    background-color: #ffffff;
    overflow-y: auto;
    height: 100%;
    margin-top: 8px;

    .loader,
    .attributeMessage {
      margin: 10px;
    }
    .loader::after {
      content: "Loading";
      animation: loadingDots 1s steps(5, end) infinite;
    }
  }

  @keyframes loadingDots {
    0% {
      content: "";
    }
    33% {
      content: ".";
    }
    66% {
      content: "..";
    }
    100% {
      content: "...";
    }
  }

  .side_filter_item h6 {
    font-size: 0.75rem;
    margin-bottom: 5px;
  }

  .side_filter_item {
    display: block;
    margin: auto;
    padding-bottom: 5px;
    margin-left: 5%;
  }

  .side_filter_item .uk-checkbox {
    border: 1px solid var(--main-color-text);
    border-radius: 1px;
  }

  /* dropdown and top click functionality */

  .mutliSelect > ul {
    list-style-type: none;
    margin: 0;
    margin-top: 5px;
    padding: 0;

    .searchFilter {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 10px;
      margin: 0px 0px 5px 10px;
    }
  }

  .multiSel {
    position: relative;
    min-height: 26px;
    background-color: white;
    color: #1a2345;
    border-radius: 5px;
    border-color: #1f8dc7;
    border-style: solid;
  }

  /* number displayed on the left side of the container code - just move jsx */
  /* .selectedCount {
  font-size: 20px;
  flex: 1;
  margin-left: 8px;
  margin-right: 20px;
  }  */

  .selectedCount {
    font-size: 0.9rem;
    text-align: right;
    margin-left: auto;
    margin-right: 7%;
    align-self: flex-end;
  }

  .filterHeadingContainer {
    width: 100%;
    display: flex;
    align-content: center;
  }

  .filterHeadingTitle {
    font-size: 0.9rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 180px;
  }

  .h3FilterHeading {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    /* Add this line */
    margin-bottom: 0px;
    word-break: break-all;
  }

  .fullHeading .filterHeadingTitle {
    white-space: wrap;
    overflow: visible;
    text-overflow: ellipsis;
  }

  /* arrow displayed on the far most right side of the container code - just move jsx */
  /* .arrow {
    width: 28%;
    display: inline;
    float: right;
    margin-right: 8px;
    margin-top: 4px;
  }  */

  .arrow {
    // display: flex;
    width: 15%;
    min-width: 25px;
    max-width: 40px;
    margin-left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      fill: var(--main-color-dark);
    }
  }

  .arrowSize {
    width: 30%;
    min-width: 8px;
  }

  .side_filter_container_inner dl.dropdown {
    margin-top: 0px;
    margin-bottom: 5px;
  }

  .clearFilter {
    color: var(--main-color-text);
    font-size: 0.75rem;
    align-self: flex-end;
    margin-right: 5px;
    margin-top: 5px;
  }

  .clearFilter a {
    color: var(--main-color-text);
  }

  .clearFilter a:hover {
    color: var(--main-color-text);
    text-decoration: underline;
    text-decoration-color: var(--main-color-text);
  }

  // @media (max-width: 767px) {
  //     .textArea,
  //     .dashButton {
  //         position: static;
  //     }
  // }

  #side_bar_display_arrow {
    display: none;
  }

  #search_toggler {
    //transform width, 50% smaller
    transform: scale(0.6);

    text-transform: capitalize;

    .uk-button.btn_primary {
      text-transform: capitalize;
    }

    .uk-button.btn_primary:hover,
    .uk-button.btn_primary.active:hover {
      font-weight: 400 !important;
    }

    .uk-button.btn_primary:hover {
      background-color: var(--btn-primary-color);
    }

    .uk-button.btn_primary.active:hover {
      background-color: var(--btn-primary-hover-color) !important;
    }
  }

  .toggle-switch {
    &-inner {
      &:before {
        background-color: #2a2e3f !important;
      }
    }
  }

  // .toggle-switch-inner {
  //    padding-left: -5px;
  // }

  .toggle-switch-inner:after {
    padding-right: 15px;
    padding-top: 1px;
  }

  .toggle-switch-inner::before {
    padding-left: 33px;
    padding-top: 1px;
  }

  #search_toggler {
    position: absolute;
    bottom: 9px;
    // right: 45px;
    // right: 106px;
    // right: 45px;
    left: -11px;
  }
}

.radioGroup {
  display: flex;
  justify-content: flex-end;
  margin: 5px;

  .radioButton {
    cursor: pointer;
    padding: 3px 2px;
    background-color: #65666636;
  }

  .andBtn {
    border-radius: 5px 0px 0px 5px;
  }
  .orBtn {
    border-radius: 0px 0px 0px 0px;
  }

  .mixedBtn {
    border-radius: 0px 0px 0px 0px;
  }

  .relatedBtn {
    border-radius: 0px 5px 5px 0px;
  }

  .radioLabel {
    margin: 5px;
    width: auto;
    color: #1a2345;
  }

  .radioButton input[type="radio"] {
    display: none;
  }

  .radioButton.selected {
    background-color: #8dc3ea;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }

  .radioButton input[type="radio"]:checked + .radioLabel {
    font-weight: bold;
  }

  @media (max-width: 1370px) and (min-width: 950px) {
    flex-direction: column; // Stack the items vertically
    align-items: stretch; // Make the items fill the full width

    .andBtn {
      border-radius: 5px 5px 0px 0px;
    }
    .relatedBtn {
      border-radius: 0px 0px 5px 5px;
    }
  }

  @media (max-width: 700px) and (min-width: 640px) {
    flex-direction: column; // Stack the items vertically
    align-items: stretch; // Make the items fill the full width

    .andBtn {
      border-radius: 5px 5px 0px 0px;
    }
    .relatedBtn {
      border-radius: 0px 0px 5px 5px;
    }
  }
}

.uk-tooltip {
  background-color: #fff !important;
  color: #fff !important;
}

.darkmode {
  .sidebar {
    background-color: var(--main-color);

    .sidebar-headers {
      .header-icons {
        background-color: var(--main-color);

        &.selected {
          background-color: #2a2e3f;
        }
      }
    }

    .radioGroup {
      .radioButton {
        background-color: #ffffff48;
      }

      .radioButton.selected {
        background-color: #8dc3ea;
      }
    }

    .side_filter_container {
      background-color: #2a2e3f;
    }

    .side_filter_container_inner {
      background-color: #2a2e3f;

      .loader {
        color: #ffffff;
      }
      .attributeMessage {
        color: #ffffff;
      }
    }
    .searchFilter::placeholder {
      color: #1a2345 !important;
    }

    .filterHeadingTitle {
      color: #ffffff;
    }

    .selectedCount {
      color: #ffffff;
    }

    .side_filter_item h6 {
      color: #ffffff !important;
    }

    .uk-checkbox {
      border: 1px solid #ffffff !important;
    }

    .clearFilter a {
      color: #ffffff;
    }

    .clearFilter a:hover {
      color: #ffffff;
      text-decoration: underline;
      text-decoration-color: #ffffff;
    }

    .arrow svg {
      fill: #ffffff;
    }

    .toggle-switch {
      &-inner {
        &:before {
          background-color: var(--main-color-text-light-blue) !important;
        }
      }
    }

    .textArea,
    .tagArea,
    .textAreaInput,
    .inputSearch {
      background-color: #2a2e3f;
      color: white;
    }
  }
}

@media (max-width: 640px) {
  .sidebar {
    position: fixed;
    z-index: 2;
    transition: left 0.3s ease-in-out !important;
    left: 0px;
    top: 80px;

    #side_bar_display_arrow {
      margin: 0px;
      display: block;
      position: absolute;
      top: 40%;
      right: -1px;
      width: auto;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;

      padding: {
        top: 7px;
        bottom: 7px;
        left: 1px;
        right: 2px;
      }

      cursor: pointer;
      background-color: #273361;

      svg {
        transform: rotate(180deg);
        height: 25px;
        fill: #ffffff;
      }
    }

    &.hidden {
      left: calc(-100% - 6px);

      #side_bar_display_arrow {
        right: -32px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;

        svg {
          transform: rotate(0deg);
        }
      }
    }

    .side_filter_container {
      height: 100%;
    }
  }
}

// Toggle button resizing
@media (max-width: 1160px) {
  #search_toggler {
    transform: scale(1) !important;
    bottom: 14px !important;
    // right: 70px !important;
    left: 15px !important;

    .toggle-switch.small-switch .toggle-switch-switch {
      margin-bottom: 2.15px;
    }
  }
}

@media (max-width: 960px) {
  #search_toggler {
    //transform width, 50% smaller
    transform: scale(0.6) !important;
    // color: red;
    bottom: 9px !important;
    // right: 45px !important;
    left: -11px !important;
  }
}

/* Modal Explanation Text Styling */
.modal-explanation {
  position: relative;
  display: inline-block;

  img {
    width: 100%;
  }

  .overlay-text {
    position: absolute;
    top: 20%;
    left: 50%;
    margin-left: 90px;
    transform: translate(-50%, -20%);
    color: white;
    font-weight: bold;
    text-align: center;
    font-size: 1.6vw; /* Relative font size based on viewport width */
    padding: 0 10px; /* Add some padding for better readability on small screens */
    width: 50%; /* Limit the width to avoid overflowing on smaller screens */

    @media (max-width: 768px) {
      font-size: 2vw; /* Increase font size for medium screens */
      top: 21%; /* Adjust positioning for medium screens */
      width: 50%; /* Width increased for medium screens */
    }

    @media (max-width: 480px) {
      font-size: 2.5vw; /* Increase font size for small screens */
      top: 22%; /* Adjust positioning for small screens */
      width: 45%; /* Increase width for smaller screens */
      margin-left: 65px; /* Adjust margin for smaller screens */
    }

    @media (min-width: 1200px) {
      font-size: 0.9vw; /* Decrease font size for large screens */
      top: 21%; /* Adjust positioning for large screens */
      width: 50%; /* Decrease width for large screens */
      margin-left: 90px; /* Adjust margin for large screens */
    }

    @media (min-width: 900px) and (max-width: 1200px) {
      font-size: 1.15vw; /* Adjust font size for screens between 900px and 1200px */
      top: 20.5%; /* Adjust positioning for screens between 900px and 1200px */
      width: 50%; /* Adjust width for screens between 900px and 1200px */
      margin-left: 90px; /* Adjust margin for screens between 900px and 1200px */
    }

    @media (min-width: 1700px) {
      font-size: 0.75vw; /* Adjust font size for screens above 1700px
      top: 20.5%; /* Adjust positioning for screens between 900px and 1200px */
      width: 50%; /* Adjust width for screens between 900px and 1200px */
      margin-left: 90px; /* Adjust margin for screens between 900px and 1200px */
    }
  }

  /* SCSS Flex utilities */
  .d-flex {
    display: flex;
  }

  .flex-column {
    flex-direction: column;
  }

  .justify-center {
    justify-content: center;
  }

  .align-center {
    align-items: center;
  }
}
