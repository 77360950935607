#main_header .bulkMenu {
  position: absolute;
  z-index: 101;
  background-color: #f4ffff;
  color: #000000;
  top: 60px;
  border: 2px solid var(--main-color-dark);
  right: 0%;
  padding: 10px;
  //   padding-left: 5px;

  .bulkIconOuter {
    display: flex;
    align-items: center;
    height: 100%;
    padding-right: 15px;
  }

  .bulkIconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    height: 50%;
    background-color: #8dc3ea;
    border-radius: 5px;
    cursor: pointer;
  }

  .bulkIconContainer .profileSize {
    width: 47px !important;
  }

  .bulkIconContainer .textContent {
    margin-left: 13px;
    height: 50%;
  }

  // All text except dark and light mode text
  .textContent {
    margin-left: 5px;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .custom_context_menu {
    position: absolute;
    // bottom: 25%;
    right: 100%;
    background-color: #f4ffff;
    border: 2px solid #273361;
    border-radius: 5px;
    z-index: 2;
    width: 0px;
    font-size: 0.8em;
    width: 100%;
    transition: opacity 0.2s ease-in-out;
    overflow: auto;
    max-height: 250px;
    // padding: 2px;
  }

  button {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    margin-left: 2px !important;
    margin-right: 2px !important;
    padding: 6px !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
    color: var(--main-color-dark);
    border-radius: 10px;
    border: solid 1px;
    border-color: var(--main-color-text-light-blue);
    background-color: #8cc1e8;
    z-index: 1;
    cursor: pointer;

    &:hover {
      background-color: #5897c4;
    }
  }

  .heading {
    font-weight: 900;
    font-size: 1em;
    display: block;
    // text-shadow: 0 0 0px #082b3d;
    margin-bottom: 5px;
  }

  .custom_context_menu_move {
    // bottom: 50%;
    top: -100%;
    right: 105%;
    padding: 10px;
    text-align: center;
    width: 110%;

    @media screen and (min-width: 240px) and (max-width: 440px) {
      left: -6% !important;
      top: 540% !important;
    }
  }

  .custom_context_menu_move_confirmation {
    bottom: 0px;
    // top: 54%;
    padding: 10px;
    text-align: center;
  }

  .custom_context_menu_copy {
    // bottom: 50%;
    // top: -128%;
    right: 105%;
    padding: 10px;
    text-align: center;
    width: 110%;

    @media screen and (min-width: 240px) and (max-width: 440px) {
      left: -6% !important;
      top: 440% !important;
      min-width: 160px;
    }
  }

  .custom_context_menu_copy_confirmation {
    bottom: 0;
    // top: 54%;
    padding: 10px;
    text-align: center;
  }

  .custom_context_keyword {
    top: 37%;
    // bottom: 5%;
    padding: 10px;
    text-align: center !important;
  }

  .custom_context_menu_delete {
    top: -68%;
    right: 105%;
    // bottom: -2%;
    padding: 10px;
    text-align: center;
  }

  .custom_context_menu_delete_confirmation {
    // top: 36%;
    // bottom: -2%;
    bottom: 0px;
    padding: 10px 10px 10px 10px;
    text-align: center;
  }

  input {
    width: 80%;
    margin-bottom: 10px;
    border: 1px solid #27336159;
  }

  .custom_context_menu_item_button {
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      text-shadow: 0 0 1px #00000081;
    }

    img {
      height: 10px;
    }
  }
}

// Its working of the icon so right padding.
.darkText {
  margin-right: 5px;
}

.lightText {
  margin-right: 5px;
}

.image_action_status_message {
  position: absolute;
  background-color: #eef6fc;
  z-index: 999999;
  top: 5px;
  right: 5px;
  border-radius: 5px;
  padding: 5px;
  font-size: initial;
}

#attribute_assign_popup {
  #selectedAttribute {
    height: 2.625em;
    width: 80%;
    margin-top: 1em;

    transition: border-color 0.1s, box-shadow 0.1s;
    border: 1px solid #d9d9d9;
    border-radius: 0.1875em;
    background: rgba(0, 0, 0, 0);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.06), 0 0 0 3px rgba(0, 0, 0, 0);
    color: inherit;
    font-size: 1rem;
    max-height: 50px;
    overflow-y: auto;
  }

  #attribute_value {
    height: 2.625em;
    width: 80%;
    margin-top: 0.25em;
    font-size: 1rem;
    // change placeholder color
    &::placeholder {
      color: #545454 !important;
    }
  }
}

.darkmode #main_header .bulkMenu {
  .custom_context_menu {
    background-color: #2b396e;
    color: #ffffff !important;
    border: 2px solid var(--main-color-dark);
  }

  .custom_context_menu_item_button:hover {
    text-decoration: underline;
  }

  .custom_context_menu input::placeholder {
    color: var(--main-color-dark) !important;
  }

  .custom_context_menu input {
    color: var(--main-color-dark) !important;
  }
}

@media screen and (max-width: 1350px) {
  #main_header .bulkMenu {
    top: 35px;
  }
}

@media screen and (max-width: 850px) {
  #main_header .bulkMenu {
    .bulkMenu {
      top: 35px;
      display: block;
    }

    .custom_context_menu_copy {
      right: 104%;
    }

    .custom_context_menu_copy_confirmation {
      width: 75%;
    }

    .custom_context_menu_move {
      right: 104%;
    }

    .custom_context_menu_move_confirmation {
      top: 37%;
      width: 75%;
    }

    .custom_context_menu_delete {
      top: -70%;
      right: 104%;
      width: 75%;
    }

    .custom_context_menu_delete_confirmation {
      top: 37%;
      width: 75%;
    }

    .custom_context_keyword {
      top: 37%;
      width: 75%;
    }
  }
}

.uk-tooltip {
  font-weight: 800;
  border: 1.5px solid #000000;
  width: auto !important;
  max-width: unset !important;
  margin-bottom: -150px !important;
}
