#profile_container {
  height: calc(100svh - 80px);
  overflow-y: auto;

  @media screen and (min-width: 900px) {
    padding-right: 40px;
    margin-top: 20px;
  }

  #profile_container_card {
    border-radius: 5px;
  }
}
