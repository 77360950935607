#clients_grid {
  .uk-card {
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #ffffff;
    padding-top: 71%;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0px 0px 3px 0px #979cab;
    transition: box-shadow 0.15s ease-in-out,
      background-position 0.05s cubic-bezier(1, 0.01, 0, 0.94),
      filter 0.2s ease-in-out;
    filter: saturate(0.9) brightness(0.9);
  }

  // FOOTER
  .uk-card-footer {
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   position: absolute;
    //   bottom: 0;
    //   left: 0;
    //   right: 0;
    //   box-sizing: border-box;
    //   background: rgba(47, 55, 82, 0.85);
    //   color: white;
    //   text-align: center;
    //   height: 25%;
    //   border: none !important;
    //   padding: 5px !important;
    //   border-radius: 5px;
    //   overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(47, 55, 82, 0.85); // Translucent footer background
    color: white; // Ensures text color is consistently white
    padding: 5px;
    border-radius: 5px;
    overflow: hidden;
  }

  .uk-card:hover {
    box-shadow: 0px 0px 5px 0px #000f3a;
    filter: saturate(1.1) brightness(1.1);
  }

  .uk-card-footer .footer_title,
  .uk-card-footer .footer_description {
    color: white !important;
    display: block;
  }

  .uk-card-footer .footer_title {
    font-size: 16px;
    font-weight: 100;
    margin: 0;
    padding: 0;
  }

  .footer_title {
    flex: 1;
    margin-right: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: white;
    display: block;

    &:hover {
      animation: scrollTitle 10s linear forwards;
      overflow: visible;
    }
  }

  .uk-card-footer .footer_description {
    font-size: 0.8rem;
    font-weight: 300;
    margin: 0;
    padding: 0;
  }

  .client_bg {
    background-position: center;
    //background-size: cover; normal
    background-size: 100% 100%; //Fits but it is stretched
  }

  @media (min-width: 400px) and (max-width: 1300px) {
    .uk-card-footer .footer_title {
      font-size: 16px !important;
    }
  }

  @media screen and (min-width: 1901px) {
    .imageSizing {
      width: 74%;
      height: auto;
    }
  }

  @media screen and (min-width: 300px) and (max-width: 1900px) {
    .imageSizing {
      width: 46%;
    }
  }
}
