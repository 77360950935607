:root {
  --main-color: #1a2345;
  --main-color-light: #1f8dc7;
  --main-color-dark: #0c1a25;
  --main-color-text: #082b3d;
  --main-color-text-light: #fbfcfccc;
  --main-color-text-light-blue: #8cc1e8;

  /* Buttons */
  --btn-primary-color: #1a2345;
  --btn-primary-hover-color: #1f8dc7;
  --btn-primary-text-color: #f5f5f5;

  --sidebar-width: 250px;

  /* Scrollbars */
  --scrollbar-background-color: #4a5a94;
  --scrollbar-track-box-shadow-color: rgba(0, 0, 0, 0.3);
  --scrollbar-thumb-color: #9ca3b9;
  --scrollbar-thumb-outline: 1px solid #9ca3b9;

  --scrollbar-background-color-dark: #1a2345;
  --scrollbar-track-box-shadow-color-dark: rgba(0, 0, 0, 0.3);
  --scrollbar-thumb-color-dark: #5b637c;
  --scrollbar-thumb-outline-dark: 1px solid #5b637c;

  /* Arrow Colors */
  --arrow-fill-light: #000000;
  /* Light mode fill color */
  --arrow-fill-dark: #ffffff;
  /* Dark mode fill color */
}

@font-face {
  /* google font link: https://fonts.google.com/specimen/Fredoka */
  font-family: "Fredoka";
  src: url(../src/assets/fonts/Fredoka/Fredoka-VariableFont_wdth.ttf);
}

@font-face {
  font-family: "DancingScript";
  src: url(../src/assets/fonts/Dancing_Script/DancingScript-VariableFont_wght.ttf);
}

@font-face {
  font-family: "Oswald";
  src: url(../src/assets/fonts/Oswald/Oswald-VariableFont_wght.ttf);
}

@font-face {
  font-family: "FjallaOne";
  src: url(../src/assets/fonts/Fjalla_One/FjallaOne-Regular.ttf);
}

@font-face {
  font-family: "Dosis";
  src: url(../src/assets/fonts/Dosis/Dosis-VariableFont_wght.ttf);
}

@font-face {
  font-family: "Catamaran";
  src: url(../src/assets/fonts/Catamaran/Catamaran-VariableFont_wght.ttf);
}

@font-face {
  font-family: "ExoTwo";
  src: url(../src/assets/fonts/Exo_2/Exo2-Italic-VariableFont_wght.ttf);
}

@font-face {
  font-family: "PoiretOne";
  src: url(../src/assets/fonts/Poiret_One/PoiretOne-Regular.ttf);
}

@font-face {
  font-family: "HammersmithOne";
  src: url(../src/assets/fonts/Hammersmith_One/HammersmithOne-Regular.ttf);
}

@font-face {
  font-family: "Montserrat";
  src: url(../src/assets/fonts/Montserrat/Montserrat-VariableFont_wght.ttf);
}

@font-face {
  font-family: "Comfortaa";
  src: url(../src/assets/fonts/Comfortaa/Comfortaa-VariableFont_wght.ttf);
}

* {
  color: var(--main-color-text);
  font-family: "Comfortaa" !important;
  transition: background-color 0.3s ease-in-out;
  position: relative;
}

body,
html {
  margin: 0px;
  overflow: hidden;
}

body {
  background-color: #eef6fc;
  height: 100vh;

  &.darkmode {
    background-color: #2a2e3f;
  }
}

.uk-button.btn_primary {
  background-color: var(--btn-primary-color);
  color: var(--btn-primary-text-color);
  border: 0px solid transparent;
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
  line-height: normal;

  &:hover {
    background-color: var(--btn-primary-hover-color);
  }

  &.active {
    background-color: var(--btn-primary-hover-color);
  }

  &.active:hover {
    background-color: var(--btn-primary-color) !important;
    font-weight: bold !important;
  }
}

::-webkit-scrollbar {
  background-color: var(--scrollbar-background-color);
  width: 0.6em;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb-color);
  outline: none;
  border-radius: 8px;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #1f1f1f !important;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #1f1f1f !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #1f1f1f !important;
}

:focus-visible {
  outline: none !important;
}

.noHightlight,
img,
button {
  -webkit-touch-callout: none; // iOS Safari
  -webkit-user-select: none; // Safari
  -khtml-user-select: none; // Konqueror HTML
  -moz-user-select: none; // Old versions of Firefox
  -ms-user-select: none; // Internet Explorer/Edge
  user-select: none; // Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox
}

.darkmode {
  ::-webkit-scrollbar {
    background-color: var(--scrollbar-background-color-dark);
    width: 0.6em;
    border-radius: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-thumb-color-dark);
    outline: none;
    border-radius: 8px;
  }

  textarea {
    background-color: #2a2e3f !important;
    color: #fff !important;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #f5f5f5 !important;
    opacity: 1;
    /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #f5f5f5 !important;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #f5f5f5 !important;
  }

  .side_filter_container_inner img.arrow {
    filter: brightness(0.2);
  }

  #page_login #login_card {
    background-color: #172027cc;
  }

  #page_login h3 {
    color: #4ea4d1 !important;
  }

  #page_login .uk-form-label {
    color: #dae9f1;
  }

  #page_login .back_btn {
    color: #dae9f1;
  }

  #page_login .resend_code {
    color: #dae9f1;
  }
}

.margin_for_mobile {
  margin-left: 0px !important;
}

/* ---------- Swal alerts ---------- */
.swal2-container {
  button.swal2-confirm {
    background-color: var(--btn-primary-color);
    transition: all 0.2s ease-in-out;
    outline: none !important;
    border: 0px solid transparent !important;

    &:hover {
      background-color: var(--btn-primary-hover-color);
    }

    &:focus {
      box-shadow: none !important;
    }
  }
}

.darkmode {
  .uk-spinner.uk-icon svg circle {
    color: #f5f5f5;
  }
}
