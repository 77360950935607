#search_results_page_component_container {
  padding-top: 14px;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 28px;
  overflow-y: auto;
  max-height: calc(100vh - 120px);

  #photos_grid {
    max-height: none;
    padding: 0px;
  }

  #no_images_container {
    margin-left: -40px;
  }

  // #no_image_div {
  //   // margin-left: 10px;
  // }

  #search_results_page_component_container_inner {
    margin-top: 40px;
    padding-left: 0px;
  }

  .inner_top_flex {
    display: flex;
  }

  #search_results_koala_image {
    width: calc(30% - 6px);
    vertical-align: top;
    height: auto;
    object-fit: contain;
  }

  #search_results_koala_speech {
    position: relative;
    width: 70%;
    height: auto;
    min-height: 300px;
    max-height: 300px;
    top: 0px;
    margin-top: 0px;
    background-color: #8dc3ea;
    border: 3px solid #8dc3ea;
    vertical-align: top;
    border-radius: 5px;
    padding: 40px;
    padding-right: 10px;

    &:before {
      content: "";
      position: absolute;
      top: 30%;
      left: -75px;
      width: 20px;
      border-bottom: 20px solid transparent;
      border-right: 55px solid #8dc3ea;
      border-top: 20px solid transparent;
    }

    p {
      height: 100%;
      overflow-y: auto;
      padding-right: 30px;
    }
  }

  h2 {
    text-align: center;
    margin-left: -35px;
    // text-decoration: underline;
    font-weight: 400;
    margin: auto;
  }

  .keyword {
    justify-content: center;
    align-items: center;
    margin-bottom: 45px;

    #search_results_koala_image {
      width: 20%;
    }
  }
}

@media (max-width: 900px) {
  #search_results_page_component_container_inner_bottom {
    // padding-left: 20px;
    margin-top: 20px;
  }
}

@media (max-width: 640px) {
  #search_results_page_component_container_inner_bottom {
    padding-left: 30px;
    // margin-top: 20px;
  }
}

.darkmode {
  #search_results_page_component_container {
    h2 {
      color: white !important;
    }

    #images_grid .uk-card {
      box-shadow: 0px 0px 3px 0px #000000c7;
    }
  }
}
