:root {
  --top-container-padding: 10%;
}

.image-container {
  position: relative;
  width: 100%; // Adjust width as necessary
  height: auto; // Adjust height as necessary
  overflow: hidden;
}

.img-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.actual-image {
  position: relative;
  width: 100%;
  height: auto;
  display: none; // Start hidden, display block when loaded
}

#single_image_page_component_container {
  margin: {
    top: 20px;
    left: 00px;
    right: 0px;
  }

  height: calc(100vh - 100px);
  border-radius: 5px;
  position: relative;
  overflow-y: auto;

  #photos_grid {
    max-height: none;
    padding: 0px !important;
  }

  #no_images_container {
    margin-left: -40px;
  }

  // #no_image_div {
  //   // margin-left: 10px;
  // }

  svg {
    fill: white;
  }

  #single_image_page_component_container_top {
    background: {
      image: linear-gradient(to bottom, #233164c4, #1a2345e1),
        url("../../assets/images/backgrounds/960x0.webp");
      color: #67a7d4;
      position: center;
      size: cover;
    }

    padding-top: 40px;
    border-radius: 5px;
    overflow: hidden;
  }

  #single_image_page_component_container_bottom {
    margin-top: 40px;
    margin-left: -5px;
    padding-right: 20px;
    padding-left: 0px;

    .title {
      color: white;
    }
  }

  h1 {
    color: white !important;
    text-align: center;
    max-height: 2.4em;
    overflow-y: hidden;
    overflow-x: auto;

    // Styling the scrollbar
    &::-webkit-scrollbar {
      height: 4px; // Smaller height for horizontal scrollbar
    }
  }

  h2,
  h3,
  p {
    color: var(--main-color-text);
  }

  h2 {
    text-align: center;
    margin-left: -35px;
    // text-decoration: underline;
    font-weight: 400;
    margin: auto;
    margin-bottom: 10px;
  }

  #image_div {
    position: relative;
    height: fit-content;
    text-align: left;
    z-index: 1;
    padding-left: var(--top-container-padding);

    img {
      border-radius: 2px;
      border: 2px solid #67a7d4;
    }

    .blurhash {
      width: 100%;
      height: 100%;
    }

    &.img_orentation_landscape img {
      width: 100%;
      height: auto;
    }

    &.img_orentation_portrait img {
      width: 50%;
      height: auto;
    }

    &.img_orentation_square img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      box-sizing: border-box;

      @media (max-width: 960px) {
        .img_orientation_square img {
          padding-right: 30px;
        }
      }
    }

    #image_actions {
      border: none !important;
      // position: absolute;
      height: calc(100% - 10px);
      // right: -30px;
      // top: 0px;
      bottom: 0px;
      // background-color: white;
      padding: 5px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      padding-top: 10px;
      text-align: right;

      .image_actions_icon {
        border: none !important;
        height: 20px;
        width: 20px;
        display: inline-block;
        opacity: 1;
        margin-bottom: 15px;
        margin-left: 15px;
        transition: filter 0.1s ease-in-out;
        cursor: pointer;
        filter: drop-shadow(2px 2px 5px #252525);

        &:hover {
          filter: drop-shadow(2px 2px 4px #000000) saturate(150%);
          transform: scale(1.05);
        }
      }
      .first_child {
        margin-left: 0px;
      }
    }

    &.img_orentation_portrait,
    &.img_orentation_square {
      #image_actions {
        text-align: left;
      }
    }

    &.img_orentation_landscape #download_icon {
      right: 10px;
    }

    &.img_orentation_portrait #download_icon {
      right: 25%;
    }

    &.img_orentation_square #download_icon {
      right: 11%;
    }
  }

  #image_info_div {
    padding-right: var(--top-container-padding);
    // padding-left: var(--top-container-padding);
    position: relative;
    z-index: 0;

    #image_actions_submit {
      color: white;
      margin-top: 10px;
      margin-bottom: 10px;
      text-align: left;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      button {
        margin-top: 0px;
        margin-bottom: 0px;
        margin-left: 0px;
        padding: 6px;
        padding-left: 8px;
        padding-right: 8px;
        color: var(--main-color-dark);
        border-radius: 10px;
        border: none;
        border-color: var(--main-color-text-light-blue);
        background-color: #8cc1e8;
        z-index: 1;
        // color: #ffff;
        cursor: pointer;

        &:hover {
          background-color: #5897c4;
        }

        svg {
          height: 15px;
          width: 15px;
          border: none;
          fill: #082b3d;
        }

        // Targets second button  (cancel button)
        &:nth-child(2) {
          margin-left: 5px !important; // Adjust the value as needed
        }

        span {
          vertical-align: middle;
        }
      }
    }
  }

  .edit_image_info {
    margin: auto;
    padding: 20px 20px 0px 0px;
    border-radius: 5px;
    margin-left: var(--top-container-padding);
    margin-right: var(--top-container-padding);
    position: relative;
    padding-right: 0px !important;
  }

  .edit_image {
    margin: auto;
    border-radius: 5px;
    margin-left: var(--top-container-padding);
    margin-right: var(--top-container-padding);
    padding: 1rem !important;
    padding-top: 3rem !important;

    overflow: auto;

    display: flex;
    align-items: center;
    justify-content: center;

    img {
      // margin-bottom: 2.5rem;
      // max-height: 75%;
      // max-width: inherit;
      width: 50%;
    }
  }

  // #image_desc_div {
  //   background-color: #bfdbf0e8;
  //   margin: auto;
  //   padding: 20px;
  //   border-radius: 5px;
  //   width: initial;
  //   position: relative;
  // }

  #image_id_div {
    position: absolute;
    right: 10px;
    top: 10px;
    color: white;
  }

  .image_bottom_div {
    width: 95%;
    background-color: #bfdbf0e8;
    margin: auto;
    padding: 20px;
    border-radius: 5px;
    width: webkit-fill-available;
    // margin-left: var(--top-container-padding);
    margin-right: var(--top-container-padding);
    position: relative;
  }

  #image_keywords_div {
    background-color: #bfdbf0e8;
    margin: auto;
    padding: 20px;
    border-radius: 5px;
    width: initial;
    position: relative;

    .warning-description {
      margin: 0px;
      padding: 0px;
    }
  }

  #image_ocr_div {
    margin-top: 1rem;
    margin-bottom: 40px;
  }

  #single_image_page_component_container_bottom {
    .image_card {
      background: {
        repeat: no-repeat;
        size: cover;
        color: #1a2345;
      }

      padding-top: 15%;
      position: relative;
      border-radius: 5px;
      overflow: hidden;
      box-shadow: 0px 0px 3px 0px #979cab;
      transition: box-shadow 0.15s ease-in-out,
        background-position 0.05s cubic-bezier(1, 0.01, 0, 0.94),
        filter 0.2s ease-in-out;
      filter: saturate(0.9) brightness(0.9);
    }
  }

  #back_icon {
    position: absolute;
    right: 50px;
    top: 9px;
    width: 70px;
    cursor: pointer;
    // z-index: 2;

    &:hover {
      filter: drop-shadow(2px 2px 4px #000000);
    }
  }
  .rotate_icon {
    border: none !important;
    opacity: 1;
    // margin-bottom: 15px;
    // margin-left: 10px;
    transition: filter 0.1s ease-in-out;
    cursor: pointer;
    filter: drop-shadow(2px 2px 5px #252525);

    position: absolute;
    left: 10px;
    top: 9px;
    width: 25px;
    padding: 0px;
    cursor: pointer;

    &:hover {
      filter: drop-shadow(2px 2px 4px #000000) saturate(150%);
      transform: scale(1.05);
    }
  }

  #enlarged_image {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 100;
    padding-left: 0px;
    background: linear-gradient(140deg, #203070 0%, #0e142e 100%);
    text-align: center;

    img {
      max-width: 100%;
      max-height: 100%;
      box-shadow: 0px 0px 20px #00000066;
    }

    // span {
    //     position: absolute;
    //     top: 10px;
    //     right: 10px;
    //     color: white;
    //     font-size: 30px;
    //     cursor: pointer;
    //     z-index: 101;
    //     font-weight: 900;

    //     &:hover {
    //         font-size: 31px;
    //     }
    // }

    .x_icon {
      width: 25px;
      position: absolute;
      top: 10px;
      right: 12px;
      z-index: 101;
      fill: #fff;
      transition: filter 0.1s ease-in-out;
      cursor: pointer;

      &:hover {
        filter: drop-shadow(2px 2px 4px #000000);
      }
    }
  }

  textarea {
    max-width: 100%;
    min-width: 100%;
    min-height: 6em;
    max-height: 10em;
    overflow-y: auto;
  }

  #input_edit_title {
    width: -webkit-fill-available;
    margin: auto;
    margin-bottom: 10px;
    display: block;
    font-size: 2.5rem;
  }

  input,
  textarea {
    border-radius: 3px;
    padding: 2px;
    outline: none !important;
    border: 1px solid #67a7d4;
    background-color: #fffffff6;
  }

  p {
    max-height: 7em;
    overflow-y: auto;

    &::-webkit-scrollbar {
      height: 5px; // Smaller height for horizontal scrollbar
    }
  }

  .delete-attribute {
    cursor: pointer;
  }

  #popup_delete_image {
    position: absolute;
    background-color: #eef6fc;
    padding: 20px;
    right: 50%;
    top: 50%;
    border-radius: 5px;
    border: 1px solid #67a7d4;
    z-index: 99999999999;
    text-align: center;
  }

  #versions_slider {
    margin-left: -40px; // to compensate for the padding, requested by Christine

    .uk-card {
      border-radius: 5px;
      overflow: hidden;
      cursor: pointer;
      background-color: #ffffff;
      box-shadow: 0px 0px 3px 0px #979cab;
      transition: box-shadow 0.15s ease-in-out, filter 0.2s ease-in-out;

      .top_image {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        height: 200px;
      }

      .uk-card-body {
        padding-top: 10px;
        padding-bottom: 10px;
        text-align: center;
      }

      &:hover {
        box-shadow: 0px 0px 5px 0px #000f3a;
        filter: saturate(1.1) brightness(1.1);
      }
    }
  }

  button {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 5px;
    padding: 6px;
    padding-left: 8px;
    padding-right: 8px;
    color: var(--main-color-dark);
    border-radius: 10px;
    border: none;
    border-color: var(--main-color-text-light-blue);
    background-color: #8cc1e8;
    z-index: 1;
    // color: #ffff;
    cursor: pointer;

    &:hover {
      background-color: #5897c4;
    }

    img {
      height: 15px;
      width: 15px;
      border: none;
    }

    span {
      vertical-align: middle;
    }
  }

  #version_message {
    button {
      margin-left: 0px !important;
    }
  }

  @media (max-width: 960px) {
    margin: 0px !important;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;

    #single_image_page_component_container_top {
      margin: 0px;
    }

    #photos_container {
      padding-left: 0px;
    }

    #image_div {
      padding-left: 0px;
      padding-right: var(--top-container-padding);

      &.img_orentation_landscape img {
        width: 90%;
        height: auto;
      }

      #image_actions,
      #image_actions_submit {
        text-align: left !important;
      }
    }

    #image_info_div {
      padding-left: 0px;
    }

    .image_bottom_div {
      margin-left: 0px;
    }

    #versions_slider {
      margin-left: -30px;
      margin-right: -30px;

      .uk-card .top_image {
        height: 100px;
      }
    }

    #single_image_page_component_container_bottom {
      margin-bottom: 0px;
      padding-right: 0;
      margin-right: -5px;
      width: 200%;
      max-width: initial;
    }

    #back_icon {
      right: 15px;
      top: 15px;
      width: 55px;
    }
  }
}

.darkmode {
  #single_image_page_component_container {
    h2 {
      color: white !important;
    }

    #images_grid .uk-card {
      box-shadow: 0px 0px 3px 0px #000000c7;
    }

    #versions_slider {
      .uk-card {
        box-shadow: 0px 0px 3px 0px #000000c7;

        .uk-card-body {
          background-color: #1a2345;

          p strong {
            color: #ffffff;
          }
        }
      }
    }

    #input_edit_title {
      background-color: #2a2e3f !important;
      color: #ffffff !important;
    }

    #version_message {
      p {
        color: #ffffff !important;
      }
    }
  }

  .black-placeholder::placeholder {
    color: rgb(150, 148, 148) !important;
  }
}

.search_result_photo_container {
  display: flex;
  justify-content: center;
  min-height: 40px;
  background-color: #bfdbf0e8;
  margin-top: 20px;
  padding: 20px;
  border-radius: 5px;
  width: webkit-fill-available;
}

#assign_attribute {
  #selectedAttribute {
    height: 2.625em;
    width: 80%;
    margin-top: 1em;

    transition: border-color 0.1s, box-shadow 0.1s;
    border: 1px solid #d9d9d9;
    border-radius: 0.1875em;
    background: rgba(0, 0, 0, 0);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.06), 0 0 0 3px rgba(0, 0, 0, 0);
    color: inherit;
    font-size: 1rem;
    max-height: 50px;
    overflow-y: auto;
  }

  #board_name {
    height: 2.625em;
    width: 80%;
    margin-top: 0.25em;
    font-size: 1rem;
    // change placeholder color
    &::placeholder {
      color: #545454 !important;
    }
  }
}

.removal_div {
  width: 93%;
  display: flex;
  padding: 5px;
  margin-bottom: 5px;
  text-align: right;

  .removal_input {
    font-family: "Comfortaa" !important;
    font-size: 14px;
    width: 57%;
    border-radius: 10px;
    border: 1px solid black;
    background-color: #fff;
    margin: 2px !important;
    padding-left: 5px !important;
    padding-right: 2px !important;
  }

  .removal_input::placeholder {
    color: #545454 !important;
  }

  .removal_submit {
    font-family: "Comfortaa" !important;
    font-size: 14px;
    font-weight: 800 !important;
    width: 42%;
    border-radius: 10px;
    background-color: #1f8dc761;
    margin: 2px !important;
    border: 1px solid black;
    // box-shadow: 0px 0px 3px 0px #000f3a;
  }
}

.removal-error-container {
  text-align: center !important;
  font-weight: 800 !important;
}

#removal-error-message {
  color: #bb2d3b !important;
}
